import React, { Component } from 'react';
import withStyles from "./jss";
import Input from "./Input";
import { isEmpty } from '../helper/util';

const styles = theme => ({
    wrapper: {
        "& .header":{
            fontSize: "0.8em",
            color: "#ffffff",
            textTransform: "uppercase",
            fontWeight: 500,
            margin: 0,
            marginBottom: 5,
        }
    },
    innerWrapper:{
        display: "flex",
        "& .date":{
            width: "50px",
            marginRight: "10px",
        }
    },
    month: {

    },
    year:{

    },
    error: {
        fontSize: "0.8em",
        padding: 5,
        backgroundColor: "#ffffff",
        color: "#ff0000",
    },
    formControl: {
        margin: "0 !important",
    }
})

class CardExpireDate extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            month: "",
            year: "",
            error: ""
         }
    }


    onChangeHandler = (event) => {
        const {month, year} = this.state;
        const name = event.target.name;
        const valueStr = event.target.value;
        const value = parseInt(valueStr, 10);
        let error = "";

        const today = new Date();
        const todayMonth = today.getMonth() + 1;
        const todayYear = today.getFullYear().toString(10).slice(2);

        if(name === "month"){
            if(!isEmpty(year, true)){
                // console.log(todayYear, year );
                // console.log(todayMonth , value );
                error = todayYear === year && todayMonth > value ? `The month cannot be less than ${todayMonth}` : "";
            }
            if(isEmpty(error, true)) error = value > 12 ? "The month can not be more than 12": "";
            
            if(
                value >= 1 && value <= 12 
                && valueStr.length === 2
                && isEmpty(error, true)) this.yearRef.focus();
        }else{
            if(!isEmpty(month, true)){
                error = todayYear === value && todayMonth > month ? `The year cannot be less than ${todayYear}` : "";
            }

            if(isEmpty(error, true)) error = value < todayYear ? `The month can not be less than ${todayYear}`: ""
        }

        this.setState({
            error,
            [name]:valueStr,
        })
    }

    render() { 
        const { classes } = this.props;
        const { month, year, error} = this.state
        return (  
            <div className={classes.wrapper}>
                <p className="header">Card Expiry Date</p>
                <div className={classes.innerWrapper}>
                    <div className={`${classes.month} date`}>
                        <Input
                            type="number"
                            name="month"
                            value={month}
                            placeholder="Mon."
                            onChange={this.onChangeHandler}
                            formControl={classes.formControl}
                            refer={el => this.monthRef = el}
                        />
                    </div>
                    <div className={`${classes.year} date`}>
                         <Input
                            type="number"
                            name="year"
                            value={year}
                            placeholder="Yr."
                            onChange={this.onChangeHandler}
                            formControl={classes.formControl}
                            refer={el => this.yearRef = el}
                        />
                    </div>
                </div>
                {
                    !isEmpty(error, true) ? (
                        <p className={classes.error}>{error}</p>
                    ): null
                }
            </div>
        )
    }
}
 
export default withStyles(styles)(CardExpireDate);