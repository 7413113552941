
import React from "react";
import PropTypes from "prop-types";

const Circle = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.81344 10.3732C2.95323 10.3732 0.626465 8.04656 0.626465 5.18662C0.626465 2.32668 2.95314 0 5.81344 0C8.67338 0 11.0001 2.32668 11.0001 5.18662C11.0001 8.04656 8.67338 10.3732 5.81344 10.3732ZM5.81344 1.90468C4.00353 1.90468 2.53123 3.37698 2.53123 5.18653C2.53123 6.99608 4.00353 8.46838 5.81344 8.46838C7.62299 8.46838 9.09529 6.99608 9.09529 5.18653C9.09529 3.37698 7.62308 1.90468 5.81344 1.90468Z" fill={color}/>
</svg>

    )
}

Circle.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Circle.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Circle
    