export const styles = theme => ({
    selectWrapper:{
        marginBottom: 20,

        width: "100%",
        "& .inner-select-wrapper":{
            width: "100%",
            position: "relative",
            "& label":{
                display: "block",
                fontSize: 14,
                marginBottom: 5,
                marginLeft: -5,
                "&.is-required":{
                    "&:after":{
                        content: "'*'",
                        color: "red",
                        marginLeft: 5,
                    }
                }
            },
            "& .input-value":{
                "&.disabled":{
                    backgroundColor: "#cccccc",
                    "& input":{
                        backgroundColor: "#cccccc",
                        pointerEvents: "none",
                        userSelect: "none",
                    }
                },
                width: "100%",
                paddingRight: 40,
                backgroundColor: "#FBFEFF",
                position: "relative",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.15)",
                "&.showArrow":{
                    "&:after":{
                        content: "''",
                        position: "absolute",
                        right: 16.5,
                        height: 7.5,
                        width: 7.5,
                        borderRight: "2px solid #3D474B",
                        borderBottom: "2px solid #3D474B",
                        transform: "rotate(45deg)",
                        top: "calc(50% - 10px)",
    
                    },
                },
                "& input":{
                    width: '100%',
                    outline: 0,
                    border: 0,
                    padding: 20,
                    paddingRight: 0,
                    backgroundColor: "#FBFEFF",
                    fontSize: "1.05em",
                    "&:hover":{
                        cursor: "pointer",
                    },
                }
                
            },
            "& .dropdown-wrapper":{
                width: "100%",
                position: "absolute",
                maxHeight: 150,
                overflowX: "hidden",
                overflowY: "auto",
                opacity: 0,
                visibility: "hidden",
                transition: "all 0.5s",
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.15)",
                zIndex: 2,
                "&.active":{
                    opacity: 1,
                    visibility: "visible",
                },
                "&.down":{
                    top: 85,
                    bottom: "unset",
                },
                "&.up":{
                    top: "unset",
                    bottom: 65,
                    "&.show-up-error":{
                        bottom: 95,
                    }
                },
                "& .group-by":{
                    "& .dropdown-item":{
                        "&:hover":{
                            backgroundColor: "#e8f4f8",
                        },
                        "&:first-child":{
                            backgroundColor: "#cef2fe",
                            fontWeight: "bold",
                            fontSize: 12,
                        }
                        
                    },
                },
                "& .dropdown-list": {
                    "& .dropdown-item":{
                        padding: "7.5px 10px",
                        cursor: "pointer",
                        fontSize: 14,
                        "&:hover":{
                            backgroundColor: "#cef2fe",
                        }

                    }
                }
            }

        }
    },
    errorMessage: {
        color: '#ff0000',
        backgroundColor: "#ffffff",
        fontSize: "0.8em",
        padding: 5,
        display: "inline-block",
        marginTop: "5px",
        borderRadius: "3px",
    },
})