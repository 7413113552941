
import React from "react";
import PropTypes from "prop-types";

const HomeAirtime = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="28" height="42" viewBox="0 0 28 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.7831 27.706C27.2929 27.706 27.706 27.2928 27.706 26.7831V4.61445C27.706 2.51095 25.9951 0.8 23.8915 0.8H4.61445C2.51095 0.8 0.8 2.51095 0.8 4.61445V37.3855C0.8 39.4891 2.51095 41.2 4.61445 41.2H23.8916C25.9951 41.2 27.706 39.4891 27.706 37.3855V30.6386C27.706 30.1289 27.2929 29.7157 26.7831 29.7157H2.6457V4.61445C2.6457 3.52905 3.52897 2.64578 4.61438 2.64578H23.8915C24.9768 2.64578 25.8602 3.52906 25.8602 4.61445V6.58313H5.57828C5.06861 6.58313 4.65539 6.99634 4.65539 7.50602C4.65539 8.01569 5.06861 8.42891 5.57828 8.42891H25.8602V26.7831C25.8602 27.2928 26.2733 27.706 26.7831 27.706ZM25.8602 31.5615V37.3855C25.8602 38.4709 24.9769 39.3542 23.8916 39.3542H4.61445C3.52905 39.3542 2.64578 38.4709 2.64578 37.3855V31.5615H25.8602Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M14.2529 32.6073C12.6809 32.6073 11.4023 33.8858 11.4023 35.4579C11.4023 37.03 12.6808 38.3085 14.2529 38.3085C15.8249 38.3085 17.1035 37.03 17.1035 35.4579C17.1035 33.8858 15.8249 32.6073 14.2529 32.6073ZM14.2529 36.4626C13.699 36.4626 13.2481 36.0117 13.2481 35.4578C13.2481 34.9039 13.699 34.453 14.2529 34.453C14.8068 34.453 15.2578 34.9039 15.2578 35.4578C15.2578 36.0117 14.8068 36.4626 14.2529 36.4626Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M16.1808 3.6916H12.3254C11.8158 3.6916 11.4025 4.10482 11.4025 4.61449C11.4025 5.12417 11.8158 5.53738 12.3254 5.53738H16.1808C16.6906 5.53738 17.1037 5.12415 17.1037 4.61449C17.1037 4.10483 16.6906 3.6916 16.1808 3.6916Z" fill={color} stroke={color} strokeWidth="0.4"/>
</svg>

    )
}

HomeAirtime.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

HomeAirtime.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default HomeAirtime
    