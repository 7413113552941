/**
 * @author Odewale Ifeoluwa
 * @package redux module
 * @description This is a module use in replace of the redux npm module.
 */
import React, {Component} from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { StateContext } from '../jss/react-jss'
import { isEmpty } from '../../helper/util';



export const connect = (stateToProps, dispatchToProps) => WrappedComponent => {
    class withConnectHOC extends Component {
        static contextType = StateContext;
        render(){
            if(typeof stateToProps !== 'function'){
                console.error('connect parameter must be a function');
                return;
            }
            const [state, dispatch] = this.context;
            
            // return <WrappedComponent {...{...stateToProps(state), ...{dispatch}}} {...this.props}/>
            let dispatchProps = {};

            if(!isEmpty(dispatchToProps) && Object.prototype.toString(dispatchToProps) === '[object Object]'){

                Object.keys(dispatchToProps).forEach(prop => {
                    dispatchProps[prop] = (...args) => {
                        return typeof dispatchToProps[prop](...args) !== 'function' ? dispatch(dispatchToProps[prop](...args)) : dispatchToProps[prop](...args)(dispatch);
                    }
                })
                
            }

            return <WrappedComponent {...{...stateToProps(state), ...dispatchProps}} {...this.props}/>
        }
    }
    hoistNonReactStatic(withConnectHOC, WrappedComponent);

    return withConnectHOC
}