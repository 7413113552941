import { 
    AUTH_LOGIN, AUTH_REGISTER, AUTH_LOGOUT,
    AUTH_AUTHORIZED, AUTH_UPDATE_PASSWORD,
    AUTH_GET_PROFILE, AUTH_RESET_PASSWORD,
    AUTH_PASSWORD_CHANGE, AUTH_ACTIVATE_USER,
} from '../constant'; 

export const authReducer = (state, action) => {
    switch (action.type) {
        case AUTH_LOGIN:
            return {
                ...state,
                login: action.payload
            };
        case AUTH_REGISTER:
            return {
                ...state,
                register: action.payload
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                logout: action.payload
            };
        case AUTH_AUTHORIZED:
            return {
                ...state,
                auth: action.payload
            };
        case AUTH_UPDATE_PASSWORD:
            return {
                ...state,
                updatePassword: action.payload
            };
        case AUTH_GET_PROFILE:
            return {
                ...state,
                getProfile: action.payload
            };
        case AUTH_RESET_PASSWORD:
            return {
                ...state,
                passwordReset: action.payload
            };
        case AUTH_PASSWORD_CHANGE:
            return {
                ...state,
                passwordChange: action.payload
            };
        case AUTH_ACTIVATE_USER:
            return {
                ...state,
                activateUser: action.payload
            };
        default:
        return state;
    }
};