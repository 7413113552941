
import React from "react";
import PropTypes from "prop-types";

const RightArrow = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 1.17839L1.17851 -0.000124931L8.43443 7.2558L1.17851 14.5117L0 13.3332L6.07741 7.2558L0 1.17839Z" fill={color}/>
</svg>

    )
}

RightArrow.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

RightArrow.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default RightArrow
    