export const styles = theme => ({
    reportWrapper: {
        minHeight: "calc(100vh - 250px)",
        "& main":{
            maxWidth: 960,
            width: "90%",
            margin: "auto",
            padding: "4em 3em",
            marginTop: 30,
            marginBottom: 30,
            boxShadow: "0 0 3px rgba(0, 0, 0, 0.15)",
            backgroundColor: "#F9F9F9",
            display: "flex",
            justifyContent: "stretch",
            alignItems: "center",
            [theme.breakpoints.mdDown]:{
                flexDirection: "column",
            },
            "& > div":{ 
                flex: 1,

                "& .alert-panel":{
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "4em 2em",
                    marginBottom: 40,
                    [theme.breakpoints.mdUp]:{
                        "&:not(.errorPage)":{
                            marginRight: 30,
                        }
                    },
                    "& h2":{
                        margin: 0,
                        marginBottom: 20,
                        textAlign: "center",
                        fontSize: "1.8em",
                        "&.success":{
                            color: "#219653"
                        },
                        "&.error":{
                            color: "#EB5757"
                        },
                    },
                    "& p":{
                        margin: 0,
                        marginBottom: 20,
                        fontSize: 16,
                    }
                },
                "& .receipt":{
                    backgroundColor: "#FFFFFF",
                    padding: "2em 2.5em",
                    display: "flex",
                    flexWrap: "wrap",
                    userSelect: "none",
                    [theme.breakpoints.mdUp]:{
                        marginLeft: 30,
                    },
                    "& .recept-item":{
                        flex: "0 0 50%",
                        borderTop: "1px solid #F0F0F0",
                        paddingTop: 20,
                        "& p":{
                            margin: 0,
                            fontSize: 13, 
                            marginBottom: 10,
                            paddingLeft: 20,
                            
                        },
                        "& h2":{
                            margin: 0,
                            marginBottom: 20,
                            fontSize: 15,
                            paddingLeft: 20,
                        },
                    }
                },
                "& h1":{
                    textTransform: "uppercase",
                    textAlign: "center",
                    userSelect: "none",
                    "&.error":{
                        color: "#d60000",
                    },
                    "&.success":{
                        color: "#079c45",

                    },
                },
                "& h2":{
                    "&.error":{
                        color: "#ED6161",
                    },
                    "&.success":{
                        color: "#219653",
                    },
                },
                "& .item":{
                    display: "flex",
                    "& > *":{
                        flex: 1,
                        margin: 0,
                        marginBottom: 10,
                    }
                }
            }
        }
    }
})