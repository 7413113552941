import { API, isEmpty } from "../../helper";
import { GET_USER_TRANSACTIONS } from "../constant";

const api = new API();
api.noAuth = false;
export const onGetTransactions = (data = {}) => {
    return async dispatch => {
        try{
            api.proxyAPI = true;
            let response = await api.post("/home/mytransactions", data);
            if(isEmpty(response.Messages)){
                response = {...response, Success: true}
            }
            // else{
            // //     if(response.Success){
            // //         response = {...response, Messages: "You have successfully created Personal Assistance"}
            // //     }
            // // }

            return dispatch({
                type: GET_USER_TRANSACTIONS,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: GET_USER_TRANSACTIONS,
                payload: {
                    Success: false,
                    Messages: "There was an error creating PA",
                    timestamps: +(new Date())
                },
            })
        }
    }
}