import React, { Component } from 'react';
import withStyles from "./jss";
import { classNames, isEmpty } from "../helper/util"


const styles = {
    wrapper:{
        position: "relative",
        "& .dropdown-innerwrapper":{

        },
        // "& .dropdown-item:first-child":{
        //     paddingTop: 20,
        // },
        "& .dropdown-item":{
            padding: 10,
            cursor: "pointer",
        },
        // "& .dropdown-item:last-child":{
        //     paddingBottom: 20,
        // },
    },
    selected: {
        cursor: "pointer",
        borderRadius: 3,
        padding: "10px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
        display: "inline-block",
        backgroundColor: "#ffffff",
        paddingRight: "40px",
        position: "relative",
        "&:after":{
            content: "''",
            border: "7px solid transparent",
            borderTopColor: "rgba(230, 121, 24, 0.91)",
            position: "absolute",
            right: 10,
            top: "calc(50% - 3.5px)"
        },
    },
    dropdownWrapper:{
        position: "absolute",
        minWidth: "100px",
        top: "calc(100% + 5px)",
        backgroundColor: "#ffffff",
        borderRadius: 3,
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.4)",
        visibility: "hidden",
        opacity: 0,
        zIndex: 5,
        transition: "all 0.3s",
        "&.active":{
            visibility: "visible",
            opacity: 1,
            transition: "all 0.3s",
            "& .dropdown-item:hover":{
                backgroundColor: "rgba(250, 174, 108, 0.22)"
            }
        }

    },
}

class DropDownSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            currentValue: "Select Item",
            isActive: false,
         }
         this.dropDownList = null;
    }
    
    componentDidMount(){
        document.addEventListener("click", this.onClickOutside);
    }

    onClickOutside = (e) => {
        const {classes} = this.props;
        const selectedClass = `.${classes.selected}`;
        const dropdownWrapperClass = `.${classes.dropdownWrapper}`;
        if(
            isEmpty(e.target.closest(dropdownWrapperClass))
            && isEmpty(e.target.closest(selectedClass))
        ){
            this.onActivateList(false)
        }
    }

    onHandleClick = (data) => {
        const { onChange } = this.props;
        if(!isEmpty(onChange)){
            onChange(data)
        }
        this.onActivateList(false)
    }

    onActivateList = (isActive) => {
        this.setState({
            isActive
        })
    }

    componentWillUnmount(){
        document.removeEventListener("click", this.onClickOutside);
    }

    render() { 
        const  { classes, placeholder, placeholderClass, items } = this.props;
        const { isActive } = this.state;
        return ( 
            <div className={classes.wrapper}
                ref={el => this.dropDownList = el}
            >
                <div className={classNames({
                    [classes.selected]: true,
                    [placeholderClass]: !isEmpty(placeholderClass, true),
                })}
                    onClick={() => this.onActivateList(!isActive)}
                >
                    {placeholder}
                </div>
                <div className={classNames({
                    [classes.dropdownWrapper]: true,
                    "active": isActive
                })}>
                    <div className="dropdown-innerwrapper">
                        {
                            items.map(item => (
                                <div key={item.key}>
                                {
                                    !isEmpty(item.divider) && item.divider ?
                                    (
                                        <hr />
                                    ) : null
                                }
                                <div 
                                    className={classNames({
                                        "dropdown-item": true,
                                        [item.className]: !isEmpty(item.className, true)
                                    })}
                                    onClick={() => this.onHandleClick(item)}>
                                    {item.label}
                                </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
         )
    }
}
 
export default withStyles(styles)(DropDownSelect);