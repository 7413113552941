
import React from "react";
import PropTypes from "prop-types";

const Debit = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.5" cy="12.5" r="12.5" fill={color}/>
<path d="M9.53553 8.94982L9.53553 10.1961L14.472 10.2005L9.09359 15.5789L9.97748 16.4628L15.3559 11.0844L15.3603 16.0209L16.6066 16.0209V8.94982H9.53553Z" fill={color}/>
</svg>

    )
}

Debit.defaultProps = {
    color: '#FED9D9',
    fontSize: 15
}

Debit.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Debit
    