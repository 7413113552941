/**
 *
 * @param name {String} property name
 * @param objectData
 */
export function propsAvailable(name, objectData) {
    return Object.prototype.hasOwnProperty.call(objectData, name);
}

// tslint:disable-next-line:no-shadowed-constiable
export function propExist(obj, props, depth, max) {
    // props[depth] is each array of properties to check in the object
    // obj[props[depth]] Get the value of the props
    const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
    // Check if the propert exist in the object
    if (!exist) {
        //  if it does not exist return false
        return false;
    }
    const newObj = obj[props[depth]];
    // Go to the child property
    const newDepth = depth + 1;
    // If the depth is attain return false
    // Else check if the child property exist
    return newDepth === max ? true : propExist(newObj, props, newDepth, max);
}

export function isEmpty(val, str = false, obj = true, num = false, arr = false, fnc = false) {
    let empty  = val === null || val === undefined;
    if (!empty && str === true) {
        empty = `${val}`.trim() === '';
    }

    if(!empty && num === true){
        empty = val === 0;
    }

    if(!empty && arr === true){
        empty = !Array.isArray(val) || val.length === 0
    }

    if (!empty &&  obj === true){
        empty = Object.keys(val).length === 0 && val.constructor === Object;
    }

    if(!empty && fnc === true){
        empty = typeof value === "function"
    }
    return empty;
}

export function deepPropsExist(obj, ...props) {
    if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
        return null;
    }

    const depth = props.length;
    return propExist(obj, props, 0, depth);
}

export function getObjValue(obj, props, depth, max) {
    // props[depth] is each array of properties to check in the object
    // obj[props[depth]] Get the value of the props
    const exist = isEmpty(obj) ? false : propsAvailable(props[depth], obj);
    // Check if the propert exist in the object
    if (!exist) {
        //  if it does not exist return false
        return null;
    }
    const newObj = obj[props[depth]];
    // Go to the child property
    const newDepth = depth + 1;
    // If the depth is attain return false
    // Else check if the child property exist
    return newDepth === max ? newObj : getObjValue(newObj, props, newDepth, max);
}

export function getDeepObjValue(obj, ...props) {
    if (isEmpty(obj) || Object.prototype.toString.call(obj) !== '[object Object]') {
        return null;
    }
    const max = props.length;
    return getObjValue(obj, props, 0, max);
}

export function isEqual(value, other) {
    // Get the value type
    const type = Object.prototype.toString.call(value);
    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) {
        return false;
    }

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
        return false;
    }

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) {
        return false;
    }

    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) {
                return false;
            }
        }
    } else {
        // eslint-disable-next-line no-unused-vars
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) {
                    return false;
                }
            }
        }
    }

    // If nothing failed, return true
    return true;

}

// Compare two items
function compare(item1, item2) {

    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!isEqual(item1, item2)) {
            return false;
        }
    } else { // Otherwise, do a simple comparison

        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) {
            return false;
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
            if (item1.toString() !== item2.toString()) {
                return false;
            }
        } else {
            if (item1 !== item2) {
                return false;
            }
        }
    }
    return true;
}

export function isObject(obj){
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export function classNames(obj){
    if(!isObject(obj)) {
        console.error("Only Object variable is allowed");
        return;
    }
    return Object.keys(obj).filter(key => obj[key] === true).join(" ")

}

export function toRGB(str, defaultColor = "rgb(100, 100, 100)"){
    
    if ((typeof str !== 'string') || str.length === 0) return defaultColor;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    const rgb = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 255;
        rgb[i] = value;
    }
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

/**
 * @platform Stackoverflow
 * @url https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
 * @param {*} items 
 */
export function groupBy(items, groupByKey = "Group"){
        return items.reduce(function(data, item) { 
            // Assign the former array as value to the variable 
          (data[item[groupByKey]] = data[item[groupByKey]] || []).push(item);
          return data;
        }, {});
}