
import React from "react";
import PropTypes from "prop-types";

const Producticon = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="241" height="261" viewBox="0 0 241 261" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="241" height="261" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M149.778 93H92.2222C87.7 93 84 96.7 84 101.222V158.778C84 163.3 87.7 167 92.2222 167H149.778C154.3 167 158 163.3 158 158.778V101.222C158 96.7 154.3 93 149.778 93ZM149.778 101.222V158.778H92.2222V101.222H149.778ZM117.464 145.334L129.798 129.424L145.667 150.556H96.3333L108.667 134.687L117.464 145.334Z" fill={color}/>
</svg>

    )
}

Producticon.defaultProps = {
    color: '#F9F9F9',
    fontSize: 15
}

Producticon.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Producticon
    