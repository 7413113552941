/**
 * @author Odewale Ifeoluwa
 * @package withStyle module
 * @description This module provide class object to component with withStyle HOD.
 */

import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext();

export const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);