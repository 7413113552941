import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Layout from "../../components/Layouts/Layout";

import {connect} from "../../common";
import Spinner from "../../common/Spinner";
import withStyle from "../../common/jss";
import SuccessAlert from "../../common/icons/SuccessAlert";
import ErrorAlert from "../../common/icons/ErrorAlert";
import Button from "../../common/Button";

import {isEmpty, isEqual, classNames, getDeepObjValue} from "../../helper";

import {getTransactionReport, saveCard} from '../../redux/actions';

import { styles } from "./style";
import {getLocalStorage} from "../../helper";
import {Dialog} from "primereact/dialog";
import Input from "../../common/Input";



class CardStatus extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            error: false,
            message: "",
            data: [],
            status: null,
            displayNameModal: false,
            cardName: null,
            payload: {}
         }
    }

    componentDidMount(){
        const { onGetReport, match: { params: { id }} } = this.props;
        if(id === '0'){
            this.setState({
                loading: false,
                error: true,
                status: "Error",
                message: "Unable to save card"
            })
        }else {
            this.setState({
                loading: false,
                error: true,
                status: "Success",
                message: "Account added successfully"
            })
        }

        // onGetReport(reportId)

    }

    componentDidUpdate(prevProps){

        const {apiGetReport, apiSaveCard} = this.props;


        // if(!isEmpty(apiSaveCard)){
        //    
        //     if(apiSaveCard.Success){
        //         window.location.href = `${process.env.REACT_APP_PAYATTITUDE}/getcardinfo/${apiSaveCard.OnFileId}`
        //         return 
        //     }
        //
        //
        //     this.setState({
        //         loading: false,
        //         error: true,
        //         status: "Error",
        //         message: apiGetReport.Messages
        //     })
        //     return 
        // }
        // if(!isEmpty(apiGetReport) && !isEqual(apiGetReport, prevProps.apiGetReport)){
        //    
        //     if(apiGetReport.Success){
        //
        //         this.setState({
        //             error: false,
        //             loading: false,
        //             status: apiGetReport.Status,
        //             data: [...apiGetReport.Data],
        //             message: `${apiGetReport.Status}`.toUpperCase() === "APPROVED" ? "Successful, you can try other transactions" : "Unfortunately this failed, try again"
        //         });
        //         return null
        //     }
        //
        //
        //     this.setState({
        //         loading: false,
        //         error: true,
        //         status: "Error",
        //         message: apiGetReport.Messages
        //     })
        // }
    }

    getIcon = () => {
        const { status } = this.state;

        if(`${status}`.toUpperCase() === "SUCCESS"){
            return <SuccessAlert fontSize="2.75em"/>
        }

        return <ErrorAlert fontSize="2.75em"/>
    }

    redirectToHome = () => {
        this.props.history.push("/")
    }

    renderAlert = () => {
        const { error, status, message } = this.state;
        return (
            <div>
                <div className={classNames({
                    "alert-panel": true,
                    "errorPage":error
                })}>
                    <span>{this.getIcon()}</span>
                    <h2 className={classNames({
                        [`${status}`.toUpperCase() === "SUCCESS" ? "success" :"error"]: true
                    })}>Payment method Added</h2>
                     <p>{message}</p>
                     {/*<Button onClick={this.addToCard}>Add to Card</Button>*/}
                     <Button onClick={this.redirectToHome}>Return to the Homepage</Button>
                </div>
            </div>
            
        )
    }

    


    render() { 
        const { loading, error } = this.state;
        const { classes } = this.props;
        return ( 
            <>
            {
                // loading ? <Spinner /> : (
                    <Layout>
                        <section className={classes.reportWrapper}>
                            <main className="inner-content">
                               {
                                   this.renderAlert() 
                               } 
                            </main>
                        </section>
                    </Layout>
                // )
            }
            </>
         )
    }

   
}
 
export default connect(
    state => ({
    }), {
    }
)(withStyle(styles)(withRouter(CardStatus)));
