export {default as CorporatePayment} from "./CorporatePayment";
export {default as Verification} from "./Verification";
export {default as Airtime} from "./Airtime";
export {default as ArrowRight} from "./ArrowRight";
export {default as BillPayment} from "./BillPayment";
export {default as BookHotels} from "./BookHotels";
export {default as Checker} from "./Checker";
export {default as Circle} from "./Circle";
export {default as CollectionLevis} from "./CollectionLevis";
export {default as Collection} from "./Collection";
export {default as Credit} from "./Credit";
export {default as Debit} from "./Debit";
export {default as Education} from "./Education";
export {default as ErrorAlert} from "./ErrorAlert";
export {default as Ewallet} from "./Ewallet";
export {default as Facebook} from "./Facebook";
export {default as FoodAndDrink} from "./FoodAndDrink";
export {default as GenerateInvoice} from "./GenerateInvoice";
export {default as GoShopping} from "./GoShopping";
export {default as HomeAirtime} from "./HomeAirtime";
export {default as HomeBillPayment} from "./HomeBillPayment";
export {default as HomeCollectionLevis} from "./HomeCollectionLevis";
export {default as HomeOthers} from "./HomeOthers";
export {default as HomeVerificationList} from "./HomeVerificationList";
export {default as Instagram} from "./Instagram";
export {default as JambResult} from "./JambResult";
export {default as Levis} from "./Levis";
export {default as Linkedin} from "./Linkedin";
export {default as ListBusiness} from "./ListBusiness";
export {default as Loader} from "./Loader";
export {default as Loans} from "./Loans";
export {default as Location} from "./Location";
export {default as Lottery} from "./Lottery";
export {default as Mail} from "./Mail";
export {default as MobileClose} from "./MobileClose";
export {default as MobileShowNew} from "./MobileShowNew";
export {default as MobileShow} from "./MobileShow";
export {default as Others} from "./Others";
export {default as PasswordChange} from "./PasswordChange";
export {default as PayTax} from "./PayTax";
export {default as PersonalAssistance} from "./PersonalAssistance";
export {default as PhoneCall} from "./PhoneCall";
export {default as Producticon} from "./Producticon";
export {default as ReceivePayment} from "./ReceivePayment";
export {default as Religion} from "./Religion";
export {default as RightArrow} from "./RightArrow";
export {default as Search} from "./Search";
export {default as SellOnPayarena} from "./SellOnPayarena";
export {default as SignOut} from "./SignOut";
export {default as SuccessAlert} from "./SuccessAlert";
export {default as Technology} from "./Technology";
export {default as Telecomms} from "./Telecomms";
export {default as Tickets} from "./Tickets";
export {default as TransactionHistory} from "./TransactionHistory";
export {default as Transportation} from "./Transportation";
export {default as Twitter} from "./Twitter";
export {default as UnifiedMoney} from "./UnifiedMoney";
export {default as UserProfile} from "./UserProfile";
export {default as WaecResult} from "./WaecResult";
export {default as Whatsapp} from "./Whatsapp";
export {default as Youtube} from "./Youtube";
