import { API, isEmpty } from "../../helper";
import { ADD_PA, GET_USER_PA, RESUME_PA } from "../constant";

const api = new API();
api.noAuth = false;
api.proxyAPI = true;

export const onAddPA = (data) => {
    return async dispatch => {
        try{
            let response = await api.post("/pa/schedule", data);
            if(isEmpty(response.Messages)){
                response = {...response, Success: true}
            }

            return dispatch({
                type: ADD_PA,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: ADD_PA,
                payload: {
                    Success: false,
                    Messages: "There was an error creating PA"
                },
            })
        }
    }
}

export const onGetPA = () => {
    return async dispatch => {
        try{
            let response = await api.get("/pa/myschedules");
            
            if(isEmpty(response.Messages)){
                response = {...response, Success: true}
            }


            return dispatch({
                type: GET_USER_PA,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: GET_USER_PA,
                payload: {
                    Success: false,
                    Messages: "There was an error creating PA",
                    timestamps: +(new Date())
                },
            })
        }
    }
}

export const onResumePA = (paId, type) => {
    return async dispatch => {
        try{
            let response = await api.get(`/pa/ModifySchedule/${paId}?action=${type}`);
            
            if(isEmpty(response.Messages)){
                response = {...response, Success: true}
            }

            return dispatch({
                type: RESUME_PA,
                payload: response
            })
        }catch(e){
            return dispatch({
                type: RESUME_PA,
                payload: {
                    Success: false,
                    Messages: "There was an error creating PA",
                    timestamps: +(new Date())
                },
            })
        }
    }
}