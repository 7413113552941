
import React from "react";
import PropTypes from "prop-types";

const Instagram = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8864 4.38232H7.85704C6.41586 4.38232 5.2688 5.5588 5.2688 6.97056V12.0294C5.2688 13.4706 6.44527 14.6176 7.85704 14.6176H12.8864C14.3276 14.6176 15.4747 13.4411 15.4747 12.0294V6.97056C15.4747 5.5588 14.3276 4.38232 12.8864 4.38232ZM10.4159 12.6176C8.70998 12.6176 7.29821 11.2353 7.29821 9.49997C7.29821 7.79409 8.68056 6.38232 10.4159 6.38232C12.1217 6.38232 13.5335 7.76468 13.5335 9.49997C13.5335 11.2059 12.1217 12.6176 10.4159 12.6176ZM13.71 6.94115C13.2982 6.94115 12.9747 6.61762 12.9747 6.20585C12.9747 5.79409 13.2982 5.47056 13.71 5.47056C14.1217 5.47056 14.4453 5.79409 14.4453 6.20585C14.4453 6.61762 14.1217 6.94115 13.71 6.94115Z" fill={color}/>
<path d="M10.4153 7.5C9.32704 7.5 8.41528 8.38235 8.41528 9.5C8.41528 10.5882 9.29763 11.5 10.4153 11.5C11.5035 11.5 12.4153 10.6176 12.4153 9.5C12.4153 8.41177 11.5035 7.5 10.4153 7.5Z" fill={color}/>
<path d="M10.3279 0C5.06318 0 0.827881 4.26471 0.827881 9.5C0.827881 14.7647 5.09259 19 10.3279 19C15.5926 19 19.8279 14.7353 19.8279 9.5C19.8573 4.2353 15.5926 0 10.3279 0ZM16.6808 12.0294C16.6808 14.1176 14.9749 15.8235 12.8867 15.8235H7.85729C5.76906 15.8235 4.06317 14.1176 4.06317 12.0294V6.97059C4.06317 4.88235 5.76906 3.17647 7.85729 3.17647H12.8867C14.9749 3.17647 16.6808 4.88235 16.6808 6.97059V12.0294Z" fill={color}/>
</svg>

    )
}

Instagram.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Instagram.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Instagram
    