export const rangeNum = (min, max) => {
    const minInt = parseInt(min, 10);
    const maxInt = parseInt(max, 10);
    const diff = maxInt > minInt ? maxInt - minInt : 0;
    const rangeArr = [...Array(diff + 1)].map((_, i) => minInt + i);
    return rangeArr;
};

export const to2dpWithoutTruncate = (num, dp = 2) => {
  const mathPow = Math.pow(10, dp);
  return parseInt(num * mathPow, 10) / mathPow;
}

export const ordinate = (num) => {
  const numInt = parseInt(num, 10);
  if(isNaN(numInt)) return num;

  const mod = numInt % 10;
  const mod100 = numInt % 100;

  let ord = `${num}th`;

  if(mod100 >= 11 && mod100 <= 19){
    ord = `${num}th`;
  }
  else if(mod === 1){
    ord = `${num}st`;
  }
  else if(mod === 2){
    ord = `${num}nd`;
  }
  else if(mod === 3){
    ord = `${num}rd`;
  }

  return ord;

}
