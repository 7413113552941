
import React from "react";
import PropTypes from "prop-types";

const TransactionHistory = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9978 8.3H15.7979L15.7978 8.49992L15.7951 15.0588V15.0589C15.7951 15.361 15.9151 15.6499 16.1284 15.8632L20.8205 20.5543L20.9619 20.6957L21.1033 20.5543L22.429 19.2286L22.5704 19.0872L22.4289 18.9457L18.0701 14.5878L18.0728 8.50009L18.0729 8.3H17.8728H15.9978Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M7.03353 12.05C8.00854 6.94685 12.4929 3.07502 17.875 3.07502C23.9681 3.07502 28.925 8.03186 28.925 14.125C28.925 20.2182 23.9682 25.175 17.875 25.175C14.4495 25.175 11.2739 23.6251 9.16113 20.9214L9.03804 20.7639L8.88047 20.8869L7.40281 22.0405L7.24508 22.1636L7.3683 22.3213C9.91464 25.5795 13.7447 27.45 17.875 27.45C25.2227 27.45 31.2 21.4718 31.2 14.125C31.2 6.77829 25.2227 0.800055 17.875 0.8C11.2342 0.8 5.72911 5.68779 4.72773 12.05H1H0.517157L0.858579 12.3914L5.54608 17.0789L5.6875 17.2204L5.82892 17.0789L10.5164 12.3914L10.8578 12.05H10.375H7.03353Z" fill={color} stroke={color} strokeWidth="0.4"/>
</svg>

    )
}

TransactionHistory.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

TransactionHistory.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default TransactionHistory
    