
import React from "react";
import PropTypes from "prop-types";

const Facebook = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.93555 0C4.67084 0 0.435547 4.26471 0.435547 9.5C0.435547 14.7647 4.70025 19 9.93555 19C15.2003 19 19.4355 14.7353 19.4355 9.5C19.4355 4.26471 15.1708 0 9.93555 0ZM12.7297 5.5C12.7297 5.58823 12.6708 5.64706 12.5826 5.64706H11.3767C11.0826 5.64706 10.8473 5.88235 10.8473 6.17647V7.44118H12.5532C12.6414 7.44118 12.7003 7.52941 12.7003 7.61765L12.5532 9.41177C12.5532 9.5 12.4944 9.55882 12.4061 9.55882H10.8473V15.4706C10.8473 15.5588 10.7885 15.6176 10.7003 15.6176H8.52378C8.43555 15.6176 8.37672 15.5588 8.37672 15.4706V9.61765V9.52941H7.28849C7.20025 9.52941 7.14143 9.47059 7.14143 9.38235V7.58824C7.14143 7.5 7.20025 7.44118 7.28849 7.44118H8.37672V5.73529C8.37672 4.52941 9.34731 3.55882 10.5532 3.55882H12.5826C12.6708 3.55882 12.7297 3.61765 12.7297 3.70588V5.5Z" fill={color}/>
</svg>

    )
}

Facebook.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Facebook.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Facebook
    