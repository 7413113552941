import { CONTACT_US } from '../constant'; 

export const contactReducer = (state, action) => {
    switch (action.type) {
        case CONTACT_US:
            return {
                ...state,
                contactUs: action.payload
            };
        default:
        return state;
    }
};