
import React from "react";
import PropTypes from "prop-types";

const Airtime = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="27" height="40" viewBox="0 0 27 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.7831 26.506C26.1824 26.506 26.506 26.1823 26.506 25.7831V3.61445C26.506 1.62141 24.8846 0 22.8915 0H3.61445C1.62141 0 0 1.62141 0 3.61445V36.3855C0 38.3786 1.62141 40 3.61445 40H22.8916C24.8846 40 26.506 38.3786 26.506 36.3855V29.6386C26.506 29.2394 26.1824 28.9157 25.7831 28.9157H1.4457V3.61445C1.4457 2.41859 2.41852 1.44578 3.61438 1.44578H22.8915C24.0873 1.44578 25.0602 2.41859 25.0602 3.61445V5.78312H4.57828C4.17906 5.78312 3.85539 6.1068 3.85539 6.50602C3.85539 6.90523 4.17906 7.22891 4.57828 7.22891H25.0602V25.7831C25.0602 26.1823 25.3838 26.506 25.7831 26.506ZM25.0602 30.3615V36.3855C25.0602 37.5814 24.0873 38.5542 22.8916 38.5542H3.61445C2.41859 38.5542 1.44578 37.5814 1.44578 36.3855V30.3615H25.0602Z" fill={color}/>
<path d="M13.253 31.8071C11.7915 31.8071 10.6024 32.9961 10.6024 34.4578C10.6024 35.9194 11.7914 37.1084 13.253 37.1084C14.7145 37.1084 15.9037 35.9194 15.9037 34.4578C15.9037 32.9961 14.7145 31.8071 13.253 31.8071ZM13.253 35.6625C12.5887 35.6625 12.0482 35.1221 12.0482 34.4577C12.0482 33.7933 12.5887 33.2528 13.253 33.2528C13.9174 33.2528 14.4579 33.7933 14.4579 34.4577C14.4579 35.1221 13.9174 35.6625 13.253 35.6625Z" fill={color}/>
<path d="M15.1807 2.8916H11.3253C10.9261 2.8916 10.6024 3.21527 10.6024 3.61449C10.6024 4.01371 10.9261 4.33738 11.3253 4.33738H15.1807C15.58 4.33738 15.9036 4.01371 15.9036 3.61449C15.9036 3.21527 15.58 2.8916 15.1807 2.8916Z" fill={color}/>
</svg>

    )
}

Airtime.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Airtime.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Airtime
    