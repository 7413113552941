
import React from "react";
import PropTypes from "prop-types";

const Checker = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.576 3.576 0 8 0C12.424 0 16 3.576 16 8C16 12.424 12.424 16 8 16C3.576 16 0 12.424 0 8ZM1.60156 7.99997C1.60156 11.528 4.47356 14.4 8.00156 14.4C11.5296 14.4 14.4016 11.528 14.4016 7.99997C14.4016 4.47197 11.5296 1.59997 8.00156 1.59997C4.47356 1.59997 1.60156 4.47197 1.60156 7.99997Z" fill={color}/>
<path d="M6.33438 9.21817L4.59299 7.47679L4 8.0656L6.33438 10.4L11.3456 5.3888L10.7568 4.79999L6.33438 9.21817Z" fill={color} stroke={color}/>
</svg>

    )
}

Checker.defaultProps = {
    color: '#219653',
    fontSize: 15
}

Checker.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Checker
    