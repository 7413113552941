
import React from "react";
import PropTypes from "prop-types";

const Youtube = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1592 9.39731L8.34005 7.87026C8.22258 7.81152 7.84082 7.89962 7.84082 8.01709V11.0125C7.84082 11.1299 8.22258 11.218 8.34005 11.1593L11.306 9.69097C11.4235 9.63224 11.2767 9.45604 11.1592 9.39731Z" fill={color}/>
<path d="M9.48532 0C4.25811 0 0 4.25812 0 9.51469C0 14.7713 4.25811 19 9.48532 19C14.7419 19 18.9706 14.7419 18.9706 9.51469C19 4.25812 14.7419 0 9.48532 0ZM15.153 11.0124C15.153 12.422 14.0077 13.5966 12.5688 13.5966H5.99073C4.58114 13.5966 3.40649 12.4513 3.40649 11.0124V7.95827C3.40649 6.54869 4.55178 5.37404 5.99073 5.37404H12.5688C13.9784 5.37404 15.153 6.51932 15.153 7.95827V11.0124Z" fill={color}/>
</svg>

    )
}

Youtube.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Youtube.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Youtube
    