import { SEND_OTP, CHANGE_PASSWORD } from '../constant'; 

export const passwordChangeReducer = (state, action) => {
    switch (action.type) {
        case SEND_OTP:
            return {
                ...state,
                sendOTP: action.payload
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        default:
        return state;
    }
};