
import React from "react";
import PropTypes from "prop-types";

const UnifiedMoney = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.4584 25.523H3.0005L9.8012 18.7226L8.69288 17.6143L0 26.3067L8.69288 34.9996L9.8012 33.8913L3.00042 27.0905H32.4584V25.523Z" fill={color}/>
<path d="M31.1459 0L30.0376 1.10832L36.8383 7.9091H7.38037V9.47666H36.8383L30.0376 16.277L31.1459 17.3854L39.8388 8.69288L31.1459 0Z" fill={color}/>
</svg>

    )
}

UnifiedMoney.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

UnifiedMoney.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default UnifiedMoney
    