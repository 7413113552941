export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BULK_MERCHANTS = 'GET_BULK_MERCHANTS';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_SIDEBAR_STATUS = 'CHANGE_SIDEBAR_STATUS';
export const CHANGE_SIDEBAR_SELECT = 'CHANGE_SIDEBAR_SELECT';
export const CHECK_VALIDATION = "CHECK_VALIDATION";
export const GET_URL = "GET_URL";
export const GET_CARDS = "GET_CARDS";


export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_AUTHORIZED = "AUTH_AUTHORIZED";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_PASSWORD_CHANGE = "AUTH_PASSWORD_CHANGE";
export const AUTH_UPDATE_PASSWORD = "AUTH_UPDATE_PASSWORD";
export const AUTH_GET_PROFILE = "AUTH_GET_PROFILE";
export const AUTH_ACTIVATE_USER = "AUTH_ACTIVATE_USER";

// Personal Assistance
export const ADD_PA = "ADD_PA";
export const GET_USER_PA = "GET_USER_PA";
export const RESUME_PA = "RESUME_PA";

// Transaction
export const GET_TRANSACTION_REPORT = "GET_TRANSACTION_REPORT";
export const SAVE_CARD = "SAVE_CARD";

// USERS DATA
export const GET_USER_TRANSACTIONS = "GET_USER_TRANSACTIONS";
export const GET_USER_FAVOURITES = "GET_USER_FAVOURITES";

// PASSWORD CHANGER
export const SEND_OTP = "SEND_OTP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// EWALLET
export const EWALLET_ACCOUNT = "EWALLET_ACCOUNT";

// CONTACT US
export const CONTACT_US = "CONTACT_US";
