
import React from "react";
import PropTypes from "prop-types";

const Location = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.75 0C3.9125 0 0 3.9125 0 8.75C0 15.3125 8.75 25 8.75 25C8.75 25 17.5 15.3125 17.5 8.75C17.5 3.9125 13.5875 0 8.75 0ZM2.5 8.75C2.5 5.3 5.3 2.5 8.75 2.5C12.2 2.5 15 5.3 15 8.75C15 12.35 11.4 17.7375 8.75 21.1C6.15 17.7625 2.5 12.3125 2.5 8.75ZM5.625 8.75C5.625 7.02411 7.02411 5.625 8.75 5.625C9.86646 5.625 10.8981 6.22062 11.4563 7.1875C12.0146 8.15438 12.0146 9.34562 11.4563 10.3125C10.8981 11.2794 9.86646 11.875 8.75 11.875C7.02411 11.875 5.625 10.4759 5.625 8.75Z" fill={color}/>
</svg>

    )
}

Location.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

Location.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Location
    