import {
    CHECK_VALIDATION,
    GET_URL,
    GET_TRANSACTION_REPORT, GET_CARDS, SAVE_CARD
} from '../constant'; 

export const transactionReducer = (state, action) => {
    switch (action.type) {
        case CHECK_VALIDATION:
            return {
                ...state,
                validation: action.payload
            };
        case GET_URL:
            return {
                ...state,
                getUrl: action.payload
            };
        case GET_TRANSACTION_REPORT:
            return {
                ...state,
                getReport: action.payload
            };
        case GET_CARDS:
            return {
                ...state,
                getCards: action.payload
            };
        case SAVE_CARD:
            return {
                ...state,
                saveCard: action.payload
            };
        default:
        return state;
    }
};