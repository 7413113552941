
import React from "react";
import PropTypes from "prop-types";

const Transportation = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.16677 18.084C6.23649 18.084 4.66675 19.6537 4.66675 21.584C4.66675 23.5143 6.23649 25.084 8.16677 25.084C10.097 25.084 11.6668 23.5143 11.6668 21.584C11.6668 19.6537 10.097 18.084 8.16677 18.084ZM8.16677 23.9173C6.8799 23.9173 5.83344 22.8708 5.83344 21.584C5.83344 20.2971 6.87997 19.2507 8.16677 19.2507C9.45358 19.2507 10.5001 20.2972 10.5001 21.584C10.5001 22.8708 9.45358 23.9173 8.16677 23.9173Z" fill={color}/>
<path d="M28 18.084C26.0697 18.084 24.5 19.6537 24.5 21.584C24.5 23.5143 26.0697 25.084 28 25.084C29.9303 25.084 31.5 23.5143 31.5 21.584C31.5 19.6537 29.9303 18.084 28 18.084ZM28 23.9173C26.7131 23.9173 25.6667 22.8708 25.6667 21.584C25.6667 20.2971 26.7132 19.2507 28 19.2507C29.2869 19.2507 30.3333 20.2972 30.3333 21.584C30.3333 22.8708 29.2869 23.9173 28 23.9173Z" fill={color}/>
<path d="M34.9076 14.8505L29.6576 6.68378C29.5503 6.51692 29.3653 6.41602 29.167 6.41602H22.7503C22.4277 6.41602 22.167 6.67736 22.167 6.99933V21.5827C22.167 21.9053 22.4277 22.166 22.7503 22.166H25.0836V20.9993H23.3336V7.58271H28.8485L33.8337 15.3376V20.9995H30.917V22.1661H34.417C34.7396 22.1661 35.0003 21.9054 35.0003 21.5828V15.1661C35.0004 15.0546 34.9683 14.9444 34.9076 14.8505Z" fill={color}/>
<path d="M25.6666 14V9.9167H30.3333V8.75H25.0833C24.7607 8.75 24.5 9.01134 24.5 9.33331V14.5834C24.5 14.9059 24.7607 15.1667 25.0833 15.1667H33.8334V14H25.6666V14Z" fill={color}/>
<path d="M22.7501 2.33398H0.583314C0.26134 2.33398 0 2.59532 0 2.9173V21.5841C0 21.9067 0.26134 22.1674 0.583314 22.1674H5.25003V21.0007H1.1667V3.50061H22.1668V21.0007H11.0834V22.1674H22.7501C23.0727 22.1674 23.3335 21.9067 23.3335 21.5841V2.9173C23.3335 2.59532 23.0727 2.33398 22.7501 2.33398Z" fill={color}/>
<path d="M4.08327 18.666H0.583252V19.8327H4.08327V18.666Z" fill={color}/>
<path d="M22.1667 18.666H12.25V19.8327H22.1667V18.666Z" fill={color}/>
<path d="M34.4166 18.666H32.0833V19.8327H34.4166V18.666Z" fill={color}/>
<path d="M22.7501 4.66602H2.33325V5.83271H22.7501V4.66602Z" fill={color}/>
<path d="M8.75044 21H7.58374V22.1667H8.75044V21Z" fill={color}/>
<path d="M28.5837 21H27.417V22.1667H28.5837V21Z" fill={color}/>
<path d="M10.5 0H2.33325V1.1667H10.5V0Z" fill={color}/>
<path d="M1.1667 0H0V1.1667H1.1667V0Z" fill={color}/>
</svg>

    )
}

Transportation.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

Transportation.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Transportation
    