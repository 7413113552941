import React, {Component} from 'react';
import withStyle from './jss';

const styles = theme => ({
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 6,
        "& .path": {
            strokeDasharray: 170,
            strokeDashoffset: 20,
        },
        "& .spinner": `
            animation: rotate 1.4s linear infinite;
            -webkit-animation: rotate 1.4s linear infinite;
            -moz-animation: rotate 1.4s linear infinite;
            width:174px;
            height:174px;
            position: relative;
        `,
        "& .spinner-dot": `
            width:274px;
            height:274px;
            position: relative;
            top: 0;
        `
    },
    loading: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        borderTop: `5px solid ${theme.primary}`,
        '-webkit-animation':'spinner 2s infinite',
        animation: 'spinner 2s infinite',
    },
    '@keyframes spinner': {
        '0%': {
            borderTopColor: theme.primary,
            transform: 'rotate(90deg)'
        },
        '33.33%': {
            borderTopColor: theme.secondary,
            transform: 'rotate(180deg)'
        },
        '66.67': {
            borderTopColor: theme.primary,
            transform: 'rotate(270deg)'
        },
        '100%': {
            borderTopColor: theme.secondary,
            transform: 'rotate(360deg)'
        }
    },
})

class Spinner extends Component{
    constructor(){
        super();
        this.state = {
            colorChanger: true,
        }
    }

    componentDidMount(){
        this.colorChanger = setInterval(() => {
            this.setState({
                colorChanger: !this.state.colorChanger
            })
        }, 1400);
        document.body.style.overflowX = "hidden";
    }

    componentWillUnmount(){
        clearInterval(this.colorChanger);
        document.body.style.overflowX = "auto";
    }

    render(){
        const { classes } = this.props;
        const { colorChanger } = this.state;
        return (
            <div className={classes.wrapper}>
                <svg className="spinner" width="174px" height="174px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="transparent" strokeWidth="2" cx="33" cy="33" r="30" stroke={`url(#${colorChanger ? "gradientOrange" : "gradientBlue"})`} />
                    <linearGradient id="gradientOrange">
                        <stop offset="50%" stopColor="#e67818" stopOpacity="1"/>
                        <stop offset="65%" stopColor="#e67818" stopOpacity=".5"/>
                        <stop offset="100%" stopColor="#e67818" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="gradientBlue">
                        <stop offset="50%" stopColor="#0193dd" stopOpacity="1"/>
                        <stop offset="65%" stopColor="#0193dd" stopOpacity=".5"/>
                        <stop offset="100%" stopColor="#0193dd" stopOpacity="0"/>
                    </linearGradient>
                    <svg className="spinner-dot dot" width="5px" height="5px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" x="37" y="1.5">
                        <circle className="path" fill={ colorChanger ? "#e67818" : "#0193dd"} cx="33" cy="33" r="30"/>
                    </svg> 
                </svg> 
            </div>
        )
    }
}

export default withStyle(styles)(Spinner);