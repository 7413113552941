import React, { Component } from 'react';
import withStyle from "../jss";
import { classNames } from "../../helper/util";
import { styles } from './style';


class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
        }
    }

    componentWillUnmount(){
        document.body.style.overflow = "hidden";
    }

    render() { 
        const {classes, children, onClose } = this.props;
        const { active } = this.state;
        return ( 
            <div className={classNames({
                    [classes.modalWrapper]: true,
                    "active": active
                })}
                ref={el => this.wrapperBorder = el}
                >
                <div className={classNames({
                    [classes.innerModalWrapper]: true,
                    "active": active
                })}>
                    <span role="button" onClick={onClose}>&times;</span>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
         )
    }
}
 
export default withStyle(styles)(Modal);
