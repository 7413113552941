import React from 'react';
import { isEmpty, classNames } from '../helper/util';
import withStyle from './jss';

const styles = theme => ({
    button: {
        textAlign: "center",
        fontSize: "1.2em",
        fontWeight: 500,
        outline: 0,
        cursor: "pointer",
        marginBottom: "20px",
        appearance: "none",
        "-webkit-appearance": "none",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
        border: 0,
        backgroundColor: theme.color.secondary,
        color: "#ffffff",
        width: "calc(100% - 20px)",
        paddingTop: 20,
        paddingBottom: 20,
        userSelect: "none",
        [theme.breakpoints.mdUp]:{
            maxWidth: 250,
        },
        "&.alt":{
            backgroundColor: "transparent",
            color: theme.color.primary,
            boxShadow: "unset",
            fontWeight: "bold",
        },
    }
})


const Button = (props) => {
    const  { 
        classes, alt, children, className, scrOnly, onClick, ariaLabel, type, ...rest
    } = props;
    return (
        <button
            className={classNames({
                [classes.button]: true,
                [className]: !isEmpty(className, true),
                "alt": alt
            })}
            type={type}
            onClick={onClick}
            {...rest}
            aria-label={ariaLabel}
        >
            {children}
        </button>
    )
}

Button.defaultProps = {
    type: "button",
    ariaLabel: "button"
}

export default withStyle(styles)(Button);
