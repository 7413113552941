
import React from "react";
import PropTypes from "prop-types";

const HomeOthers = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="40" height="11" viewBox="0 0 40 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.18662 10.3733C2.32668 10.3733 0 8.04656 0 5.18662C0 2.32668 2.32668 0 5.18662 0C8.04683 0 10.3736 2.32668 10.3736 5.18662C10.3736 8.04656 8.04692 10.3733 5.18662 10.3733ZM5.18662 1.90477C3.37707 1.90477 1.90477 3.37707 1.90477 5.18662C1.90477 6.99617 3.37707 8.46847 5.18662 8.46847C6.99653 8.46847 8.46883 6.99617 8.46883 5.18662C8.46883 3.37707 6.99653 1.90477 5.18662 1.90477Z" fill={color}/>
<path d="M20 10.3734C17.14 10.3734 14.8134 8.04668 14.8134 5.18674C14.8134 2.3268 17.14 0.00012207 20 0.00012207C22.8599 0.00012207 25.1866 2.3268 25.1866 5.18674C25.1866 8.04668 22.8599 10.3734 20 10.3734ZM20 1.9048C18.1904 1.9048 16.7181 3.3771 16.7181 5.18665C16.7181 6.9962 18.1904 8.46851 20 8.46851C21.8095 8.46851 23.2818 6.9962 23.2818 5.18665C23.2818 3.3771 21.8096 1.9048 20 1.9048Z" fill={color}/>
<path d="M34.8134 10.3734C31.9532 10.3734 29.6265 8.04668 29.6265 5.18674C29.6265 2.3268 31.9531 0.00012207 34.8134 0.00012207C37.6734 0.00012207 40.0001 2.3268 40.0001 5.18674C40.0001 8.04668 37.6734 10.3734 34.8134 10.3734ZM34.8134 1.9048C33.0035 1.9048 31.5312 3.3771 31.5312 5.18665C31.5312 6.9962 33.0035 8.46851 34.8134 8.46851C36.623 8.46851 38.0953 6.9962 38.0953 5.18665C38.0953 3.3771 36.6231 1.9048 34.8134 1.9048Z" fill={color}/>
</svg>

    )
}

HomeOthers.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

HomeOthers.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default HomeOthers
    