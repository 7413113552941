import { GET_USER_TRANSACTIONS } from '../constant'; 

export const authdataReducer = (state, action) => {
    switch (action.type) {
        case GET_USER_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload
            };
        default:
        return state;
    }
};