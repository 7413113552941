import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyle from './jss';
import { isEmpty, classNames } from '../helper/util';

const styles = theme => ({
  dropdownSelect: {
    position: 'relative',
  },
  selectedItem: {
    cursor: 'pointer',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    userSelect: 'none',
  },
  menuList: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    // width: '100%',
    borderRadius: '3px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    opacity: 0,
    transition: 'all 0.5s',
    top: '40px',
    width: '150px',
    left: '-40px',
    zIndex: 2000,
    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: '#ffffff',
      width: '20px',
      height: '20px',
      transform: 'rotate(45deg)',
      left: 'calc(50% - 10px)',
      top: '-10px',
      zIndex: '-1',
      opacity: 0,
      // boxShadow: '0px 0px 5px rgba(0, 0, 0, 0)',
      transition: 'all 0.5s',
    },
    '&.active': {
      top: '50px',
      opacity: 1,
      transition: 'all 0.5s',
      '&:before': {
        opacity: 1,
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        transition: 'opacity 0.5s',
      },
    },
  },
  menuItem: {
    minHeight: '20px',
    width: '100%',
    padding: '10px',
    cursor: 'pointer',
    userSelect: 'none',
    '&:not(:last-child)': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.3)`,
    },
  },
});

class Dropdown extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
    };
  }

  onToggleDropdown = () => {
    this.setState({ active: !this.state.active });
  };

  onChangeDataType = data => {
    const { onChangeDataType } = this.props;

    onChangeDataType(data);
  };

  render() {
    const { classes, className, data, dropdownAnchor } = this.props;
    const { active } = this.state;

    return (
      <div 
        className={classNames({
          [classes.dropdownSelect]: true,
          [className]: !isEmpty(classNames, true)
        })} 
        onClick={this.onToggleDropdown}
      >
        {/* <div className={classes.selectedItem}>{dropdownAnchor}</div> */}
        {dropdownAnchor}
        {/* <div className={classes.wrapper}> */}
        <div className={[classes.menuList, active ? 'active' : ''].join(' ')}>
          {!isEmpty(data)
            ? data.map(datum => (
                <div
                  className={classNames({
                    [classes.menuItem]: true,
                    "optionslist": true,
                    [datum.className]: !isEmpty(datum.className, true) 
                  })}
                  key={datum.id}
                  onClick={() => this.onChangeDataType(datum)}
                >
                  {datum.label}
                </div>
              ))
            : null}
        </div>
        {/* </div> */}
      </div>
    );
  }
}

Dropdown.propTypes = {
  onChangeDataType: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.string,
      key: PropTypes.string || PropTypes.number
    }),
  ),
  dropdownAnchor: PropTypes.any
};

export default withStyle(styles)(Dropdown);
