
import React from "react";
import PropTypes from "prop-types";

const HomeBillPayment = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.7959 1.06319L25.7959 1.06321L23.9579 2.90109L22.1201 1.06328C21.7691 0.712324 21.2001 0.712351 20.8491 1.06327L20.8491 1.06328L19.0112 2.90115L17.1735 1.06329L17.1735 1.06328C16.8226 0.712324 16.2535 0.712351 15.9025 1.06327L15.9025 1.06328L14.0646 2.90116L12.2267 1.06328C11.8757 0.712334 11.3067 0.712334 10.9557 1.06328L9.11788 2.90115L7.28014 1.06329L7.28014 1.06328C6.92918 0.712324 6.36014 0.71235 6.00913 1.06327L6.00912 1.06328L4.17124 2.90115L2.33344 1.06329L2.33344 1.06328C1.98445 0.7143 1.42021 0.712746 1.06857 1.05819L1.06853 1.05823C0.884397 1.23923 0.794794 1.48049 0.800234 1.71955V33.1406C0.800234 35.2544 2.51958 36.9737 4.63337 36.9737H29.3659H29.3659H29.366H29.366H29.366H29.366H29.3661H29.3661H29.3661H29.3662H29.3662H29.3662H29.3663H29.3663H29.3663H29.3664H29.3664H29.3664H29.3665H29.3665H29.3665H29.3665H29.3666H29.3666H29.3666H29.3667H29.3667H29.3667H29.3668H29.3668H29.3668H29.3668C31.4807 36.9737 33.2 35.2544 33.2 33.1406V18.8869C33.2 18.3905 32.7976 17.9882 32.3013 17.9882H27.33V1.69876C27.33 1.3353 27.111 1.00757 26.7753 0.868459C26.4393 0.729231 26.0528 0.806373 25.7959 1.06319ZM26.1215 35.1763H4.63337C3.51096 35.1763 2.59758 34.2629 2.59758 33.1405V3.86933L3.53577 4.80752C3.88673 5.15848 4.45577 5.15845 4.80677 4.80753L4.80679 4.80752L6.64466 2.96964L8.48254 4.80752C8.8335 5.15848 9.40254 5.15845 9.75354 4.80753L9.75356 4.80752L11.5914 2.96964L13.4294 4.80752C13.7803 5.15847 14.3494 5.15847 14.7003 4.80752L16.5382 2.96964L18.3761 4.80752C18.727 5.15848 19.2961 5.15845 19.6471 4.80753L19.6471 4.80752L21.485 2.96964L23.3228 4.80752L23.3229 4.80753C23.6739 5.15844 24.2429 5.15849 24.5938 4.8075L25.533 3.86833V18.8869C25.533 18.8974 25.5337 18.9063 25.5339 18.91L25.5339 18.9102L25.534 18.9114V33.1405C25.534 33.8883 25.7499 34.5863 26.1215 35.1763ZM31.4026 33.1405C31.4026 34.2629 30.4891 35.1763 29.3668 35.1763C28.2445 35.1763 27.331 34.2629 27.331 33.1405V19.7856H31.4026V33.1405Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M21.0845 11.8999H19.7228C19.2264 11.8999 18.824 12.3022 18.824 12.7986C18.824 13.2949 19.2264 13.6973 19.7228 13.6973H21.0845C21.5809 13.6973 21.9832 13.2949 21.9832 12.7986C21.9832 12.3022 21.5809 11.8999 21.0845 11.8999Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M8.40753 11.8999H7.04575C6.5494 11.8999 6.14705 12.3022 6.14705 12.7986C6.14705 13.2949 6.5494 13.6973 7.04575 13.6973H8.40753C8.90388 13.6973 9.30624 13.2949 9.30624 12.7986C9.30624 12.3022 8.90388 11.8999 8.40753 11.8999Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M22.3372 20.5644V20.5644C22.3371 20.0681 21.9349 19.6657 21.4385 19.6657H10.3694C9.87301 19.6657 9.47065 20.0681 9.47065 20.5644C9.47065 21.0608 9.87301 21.4631 10.3694 21.4631H21.4385C21.9348 21.4631 22.3372 21.0608 22.3372 20.5644Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M7.32715 19.929C7.15974 19.7616 6.92862 19.6657 6.69175 19.6657C6.45487 19.6657 6.22376 19.7616 6.05634 19.929C5.88893 20.0964 5.79304 20.3276 5.79304 20.5644C5.79304 20.8013 5.88893 21.0324 6.05634 21.1998C6.22376 21.3673 6.45487 21.4631 6.69175 21.4631C6.92862 21.4631 7.15974 21.3673 7.32715 21.1998C7.49457 21.0324 7.59045 20.8013 7.59045 20.5644C7.59045 20.3276 7.49457 20.0964 7.32715 19.929Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M22.3372 25.1755V25.1755C22.3371 24.6792 21.9349 24.2768 21.4385 24.2768H10.3694C9.87301 24.2768 9.47065 24.6792 9.47065 25.1755C9.47065 25.6719 9.87301 26.0742 10.3694 26.0742H21.4385C21.9348 26.0742 22.3372 25.6719 22.3372 25.1755Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M7.32715 24.5401C7.15974 24.3727 6.92862 24.2768 6.69175 24.2768C6.45487 24.2768 6.22376 24.3727 6.05634 24.5401C5.88893 24.7075 5.79304 24.9386 5.79304 25.1755C5.79304 25.4124 5.88893 25.6435 6.05634 25.8109C6.22376 25.9783 6.45487 26.0742 6.69175 26.0742C6.92862 26.0742 7.15974 25.9783 7.32715 25.8109C7.49457 25.6435 7.59045 25.4124 7.59045 25.1755C7.59045 24.9386 7.49457 24.7075 7.32715 24.5401Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M21.4385 28.8879H10.3694C9.87301 28.8879 9.47065 29.2902 9.47065 29.7866C9.47065 30.2829 9.87301 30.6853 10.3694 30.6853H21.4385C21.9348 30.6853 22.3372 30.2829 22.3372 29.7866C22.3372 29.2902 21.9348 28.8879 21.4385 28.8879Z" fill={color} stroke={color} strokeWidth="0.4"/>
<path d="M7.32715 29.1512C7.15974 28.9838 6.92862 28.8879 6.69175 28.8879C6.45487 28.8879 6.22376 28.9838 6.05634 29.1512C5.88893 29.3186 5.79304 29.5497 5.79304 29.7866C5.79304 30.0235 5.88893 30.2546 6.05634 30.422C6.22376 30.5894 6.45487 30.6853 6.69175 30.6853C6.92862 30.6853 7.15974 30.5894 7.32715 30.422C7.49457 30.2546 7.59045 30.0235 7.59045 29.7866C7.59045 29.5497 7.49457 29.3186 7.32715 29.1512Z" fill={color} stroke={color} strokeWidth="0.4"/>
</svg>

    )
}

HomeBillPayment.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

HomeBillPayment.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default HomeBillPayment
    