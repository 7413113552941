
import React from "react";
import PropTypes from "prop-types";

const GoShopping = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.3566 6.67497H7.08143L6.61654 1.4959C6.5405 0.648958 5.83069 0 4.98038 0H1.64273C0.735475 0 0 0.735475 0 1.64273C0 2.54999 0.735475 3.28547 1.64273 3.28547H3.47853C4.48177 14.4625 1.88891 -14.4259 5.3679 24.3351C5.50198 25.8522 6.32171 27.4984 7.73383 28.617C5.18783 31.8684 7.5141 36.6617 11.6556 36.6617C15.093 36.6617 17.5174 33.2333 16.3386 29.9864H25.3295C24.1522 33.2292 26.5714 36.6617 30.0126 36.6617C32.7588 36.6617 34.993 34.4275 34.993 31.6813C34.993 28.9351 32.7588 26.7009 30.0126 26.7009H11.6667C10.4188 26.7009 9.33143 25.9481 8.86208 24.8512L35.1151 23.3083C35.8318 23.2662 36.4382 22.7633 36.6124 22.0668L39.9503 8.71611C40.2091 7.68087 39.4254 6.67497 38.3566 6.67497ZM11.6556 33.3762C10.7211 33.3762 9.96075 32.6158 9.96075 31.6813C9.96075 30.7467 10.7211 29.9864 11.6556 29.9864C12.5901 29.9864 13.3505 30.7467 13.3505 31.6813C13.3505 32.6158 12.5901 33.3762 11.6556 33.3762ZM30.0125 33.3762C29.0779 33.3762 28.3176 32.6158 28.3176 31.6813C28.3176 30.7467 29.0779 29.9864 30.0125 29.9864C30.9471 29.9864 31.7074 30.7467 31.7074 31.6813C31.7074 32.6158 30.9471 33.3762 30.0125 33.3762ZM33.7177 20.0993L8.41987 21.586L7.37634 9.96036H36.2526L33.7177 20.0993Z" fill={color}/>
</svg>

    )
}

GoShopping.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

GoShopping.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default GoShopping
    