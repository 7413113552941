import { EWALLET_ACCOUNT } from '../constant'; 

export const ewalletReducer = (state, action) => {
    switch (action.type) {
        case EWALLET_ACCOUNT:
            return {
                ...state,
                account: action.payload
            };
        default:
        return state;
    }
};