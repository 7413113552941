
import React from "react";
import PropTypes from "prop-types";

const MobileShowNew = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} fill="none" height="18" viewBox="0 0 27 18" width="27" xmlns="http://www.w3.org/2000/svg">
            <rect width="27" height="3.6" x="0" y="0" fill={color}/>
            <rect width="18" height="3.6" x="9" y="7.2" fill={color}/>
            <rect width="9" height="3.6" x="18" y="14.4" fill={color}/>
        </svg>
    )
}

MobileShowNew.defaultProps = {
    color: '#3d474b',
    fontSize: 15
}

MobileShowNew.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default MobileShowNew
    