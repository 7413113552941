import React, {Component} from 'react';
import withStyle from './jss';

const styles = theme => ({
    path: {
        strokeDasharray: 170,
        strokeDashoffset: 20,
    },
    spinner: `
        animation: rotate 1.4s linear infinite;
        -webkit-animation: rotate 1.4s linear infinite;
        -moz-animation: rotate 1.4s linear infinite;
        position: relative;
    `,
    spinnerDot: `
        position: relative;
        top: 0;
    `,
    loading: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        borderTop: `5px solid ${theme.primary}`,
        '-webkit-animation':'spinner 2s infinite',
        animation: 'spinner 2s infinite',
    },
    '@keyframes spinner': {
        '0%': {
            borderTopColor: theme.primary,
            transform: 'rotate(90deg)'
        },
        '33.33%': {
            borderTopColor: theme.secondary,
            transform: 'rotate(180deg)'
        },
        '66.67': {
            borderTopColor: theme.primary,
            transform: 'rotate(270deg)'
        },
        '100%': {
            borderTopColor: theme.secondary,
            transform: 'rotate(360deg)'
        }
    },
})

class LoaderIcon extends Component{
    constructor(){
        super();
        this.state = {
            colorChanger: true,
        }
    }

    componentDidMount(){
        this.colorChanger = setInterval(() => {
            this.setState({
                colorChanger: !this.state.colorChanger
            })
        }, 1400);

        document.body.style.overflowX = "hidden";
    }

    componentWillUnmount(){
        clearInterval(this.colorChanger);
        document.body.style.overflowX = "hidden";
    }

    render(){
        
        const { classes, color, scale } = this.props;
        const pointer = 66 * scale;
        const id = Math.random().toString(32).substring(0, 8);
        return (
                <svg className={classes.spinner} width={`${100 * scale}px`} height={`${100 * scale}px`} viewBox={`0 0 ${pointer} ${pointer}`} xmlns="http://www.w3.org/2000/svg">
                    <circle className={classes.path} fill="transparent" strokeWidth={4 * scale} cx={(pointer / 2)} cy={(pointer / 2)} r={(pointer / 2) - (3 * scale)}
                    stroke={`url(#gradientOrange-${id})`} />
                    <linearGradient id={`gradientOrange-${id}`}>
                        <stop offset="50%" stopColor={color} stopOpacity="1"/>
                        <stop offset="65%" stopColor={color} stopOpacity=".5"/>
                        <stop offset="100%" stopColor={color} stopOpacity="0"/>
                    </linearGradient>
                    <svg className={`${classes.spinnerDot} dot`} width={10 * scale} height={10 * scale} viewBox={`0 0 ${pointer} ${pointer}`} xmlns="http://www.w3.org/2000/svg" x={37 * scale} y={1.5 * scale} >
                        <circle className={classes.path} fill={ color } cx={(pointer / 2)} cy={(pointer / 2)} r={(pointer / 2) - (3 * scale)}/>
                    </svg> 
                </svg> 
        )
    }
}

LoaderIcon.defaultProps = {
    color: "#ffffff",
    scale: 0.2,
    hidden: false,
}

export default withStyle(styles)(LoaderIcon);