
import React from "react";
import PropTypes from "prop-types";

const CollectionLevis = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.6939 14.1377C35.6934 14.1377 35.6929 14.1377 35.6925 14.1377H1.12712C0.800303 14.1373 0.535014 14.4019 0.534668 14.7288C0.534668 14.7292 0.534668 14.7297 0.534668 14.7301V31.3215C0.534323 31.6483 0.798921 31.9136 1.12574 31.914C1.12617 31.914 1.12669 31.914 1.12712 31.914H24.6316C24.9587 31.914 25.224 31.6487 25.224 31.3215C25.224 30.9944 24.9587 30.7291 24.6316 30.7291H1.71957V15.3226H35.1V16.8041C35.0997 17.1309 35.3643 17.3962 35.6911 17.3965C35.6915 17.3965 35.6921 17.3965 35.6925 17.3965C36.0193 17.3969 36.2846 17.1323 36.2849 16.8055C36.2849 16.805 36.2849 16.8045 36.2849 16.8041V14.7301C36.2853 14.4033 36.0207 14.1381 35.6939 14.1377Z" fill={color}/>
<path d="M31.6745 11.3875L4.0222 5.1657C3.86873 5.1314 3.70791 5.15922 3.57492 5.24313C3.44211 5.32773 3.34818 5.46141 3.31361 5.61505L1.93099 11.8368C1.85996 12.1564 2.06148 12.473 2.38104 12.544C2.70059 12.6151 3.01721 12.4135 3.08824 12.094L4.34158 6.45223L31.4146 12.5433C31.4574 12.5533 31.5012 12.5582 31.5452 12.5579C31.8724 12.5573 32.1372 12.2915 32.1366 11.9644C32.1361 11.6878 31.9443 11.4484 31.6745 11.3875Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M31.7186 11.1921C32.0794 11.2736 32.336 11.5937 32.3367 11.9637C32.3374 12.4011 31.9836 12.7564 31.5463 12.7576C31.4868 12.758 31.4277 12.7513 31.3702 12.7381C31.37 12.738 31.3705 12.7381 31.3702 12.7381L4.49343 6.69109L3.28358 12.1371C3.18858 12.5644 2.76514 12.834 2.33773 12.739C1.91038 12.644 1.64085 12.2206 1.73585 11.7932C1.73585 11.7931 1.73585 11.7932 1.73585 11.7932L3.11846 5.57139C3.16463 5.36619 3.29019 5.18714 3.46757 5.07416C3.64529 4.96203 3.86089 4.92441 4.06592 4.97024L31.7186 11.1921ZM1.93109 11.8365C1.86006 12.1561 2.06157 12.4727 2.38113 12.5437C2.70069 12.6148 3.0173 12.4133 3.08834 12.0937L4.34168 6.45194L31.4147 12.5431C31.4575 12.553 31.5013 12.5579 31.5453 12.5576C31.8725 12.557 32.1373 12.2912 32.1367 11.9641C32.1362 11.6875 31.9444 11.4481 31.6746 11.3872L4.02229 5.16542C3.86882 5.13112 3.70801 5.15894 3.57502 5.24285C3.4422 5.32745 3.34827 5.46113 3.3137 5.61477L1.93109 11.8365Z" fill={color}/>
<path d="M28.3168 7.95896L11.7255 1.04588C11.4371 0.925679 11.1048 1.04916 10.965 1.32862L9.5824 4.09385C9.42625 4.38265 9.53375 4.74342 9.82263 4.89957C10.1114 5.05572 10.4722 4.94822 10.6284 4.65934C10.6345 4.64802 10.6403 4.63645 10.6456 4.62469V4.62608L11.7801 2.35582L27.8592 9.05528C28.162 9.18162 28.5099 9.03869 28.6362 8.7359C28.7626 8.43328 28.6196 8.08538 28.3168 7.95896Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.394 7.77423C28.7987 7.9432 28.9898 8.40822 28.8209 8.81278C28.652 9.21753 28.187 9.4085 27.7824 9.23973C27.7823 9.23972 27.7824 9.23975 27.7824 9.23973L11.8756 2.61209L10.8246 4.71531C10.8183 4.72872 10.8112 4.74172 10.8043 4.75448L10.6606 4.67661L10.6236 4.66789C10.6253 4.665 10.6269 4.6621 10.6284 4.65918C10.6343 4.6483 10.6399 4.63719 10.6451 4.62591C10.645 4.626 10.6451 4.62583 10.6451 4.62591L10.6659 4.58548L10.8457 4.62453V4.62591H10.6488M10.6606 4.67661L10.8043 4.75448C10.5956 5.14056 10.1135 5.28401 9.72759 5.07534C9.34253 4.86719 9.19864 4.38701 9.40494 4.00158L10.7862 1.23902C10.9731 0.865549 11.4171 0.700484 11.8025 0.8611C11.8025 0.861104 11.8025 0.861096 11.8025 0.8611L28.394 7.77423M28.3169 7.95879L11.7255 1.04572L28.3169 7.95879Z" fill={color}/>
<path d="M28.7359 16.9033H23.2489C22.9218 16.9033 22.6565 17.1686 22.6565 17.4958C22.6565 17.8229 22.9218 18.0882 23.2489 18.0882H28.7359C29.063 18.0882 29.3283 17.8229 29.3283 17.4958C29.3283 17.1686 29.063 16.9033 28.7359 16.9033Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.249 16.7031H28.7359C29.1735 16.7031 29.5284 17.058 29.5284 17.4956C29.5284 17.9332 29.1735 18.288 28.7359 18.288H23.249C22.8114 18.288 22.4565 17.9332 22.4565 17.4956C22.4565 17.058 22.8114 16.7031 23.249 16.7031ZM23.249 16.9031H28.7359C29.0631 16.9031 29.3284 17.1684 29.3284 17.4956C29.3284 17.8227 29.0631 18.088 28.7359 18.088H23.249C22.9218 18.088 22.6565 17.8227 22.6565 17.4956C22.6565 17.1684 22.9218 16.9031 23.249 16.9031Z" fill={color}/>
<path d="M17.7185 16.9424C14.3587 16.9424 11.635 19.666 11.635 23.0259C11.6385 26.3843 14.3601 29.1059 17.7185 29.1094C21.0784 29.1094 23.802 26.3857 23.802 23.0259C23.802 19.666 21.0784 16.9424 17.7185 16.9424ZM17.7185 28.0033C14.9695 28.0033 12.7411 25.7749 12.7411 23.0259C12.7441 20.2782 14.9708 18.0515 17.7185 18.0485C20.4675 18.0485 22.6959 20.2769 22.6959 23.0259C22.6959 25.7749 20.4675 28.0033 17.7185 28.0033Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.4351 23.0259L11.4351 23.0257C11.4351 19.5554 14.2483 16.7422 17.7186 16.7422C21.1889 16.7422 24.0021 19.5554 24.0021 23.0257C24.0021 26.496 21.1889 29.3092 17.7186 29.3092H17.7184C14.2497 29.3056 11.4386 26.4946 11.4351 23.0259ZM12.9412 23.0258C12.9412 25.6643 15.0801 27.8031 17.7186 27.8031C20.3571 27.8031 22.496 25.6642 22.496 23.0257C22.496 20.3872 20.3572 18.2483 17.7187 18.2483C15.0814 18.2512 12.9441 20.3885 12.9412 23.0258ZM17.7186 18.0483C14.9709 18.0513 12.7442 20.278 12.7412 23.0257C12.7412 25.7747 14.9696 28.0031 17.7186 28.0031C20.4676 28.0031 22.696 25.7747 22.696 23.0257C22.696 20.2767 20.4676 18.0483 17.7186 18.0483ZM11.6351 23.0257C11.6351 19.6659 14.3587 16.9422 17.7186 16.9422C21.0784 16.9422 23.8021 19.6659 23.8021 23.0257C23.8021 26.3855 21.0784 29.1092 17.7186 29.1092C14.3602 29.1057 11.6385 26.3841 11.6351 23.0257Z" fill={color}/>
<path d="M12.1879 27.9636H6.96231L4.48467 25.2536V20.7601L7.22086 18.0882H12.1879C12.5151 18.0882 12.7804 17.8229 12.7804 17.4958C12.7804 17.1686 12.5151 16.9033 12.1879 16.9033H6.98029C6.82543 16.903 6.67654 16.9633 6.5655 17.0713L3.47743 20.0833C3.36207 20.2181 3.29881 20.3898 3.29907 20.5673V25.4838C3.29907 25.6317 3.35429 25.7742 3.45393 25.8834L6.26271 28.9556C6.37496 29.0785 6.53379 29.1485 6.70031 29.1485H12.1879C12.5151 29.1485 12.7804 28.8832 12.7804 28.556C12.7804 28.2288 12.5152 27.9636 12.1879 27.9636Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.56555 17.0711C6.67659 16.9631 6.82548 16.9028 6.98033 16.9031H12.188C12.5151 16.9031 12.7804 17.1684 12.7804 17.4956C12.7804 17.8227 12.5151 18.088 12.188 18.088H7.22091L4.48471 20.7599V25.2534L6.96236 27.9634H12.188C12.5152 27.9634 12.7804 28.2286 12.7804 28.5558C12.7804 28.883 12.5151 29.1483 12.188 29.1483H6.70035C6.53384 29.1483 6.37501 29.0783 6.26276 28.9554L3.45397 25.8832C3.35434 25.774 3.29912 25.6315 3.29912 25.4836V20.5671C3.29886 20.3896 3.36212 20.2179 3.47748 20.0831L6.56555 17.0711ZM3.09912 20.5672V25.4836C3.09912 25.6813 3.17296 25.8719 3.30621 26.018L6.11509 29.0903C6.11507 29.0903 6.11511 29.0903 6.11509 29.0903C6.26519 29.2545 6.47762 29.3483 6.70035 29.3483C6.70032 29.3483 6.70039 29.3483 6.70035 29.3483H12.188C12.6256 29.3483 12.9804 28.9934 12.9804 28.5558C12.9804 28.1182 12.6257 27.7634 12.188 27.7634H7.0505L4.68471 25.1758V20.8442L7.30236 18.288H12.188C12.6256 18.288 12.9804 17.9332 12.9804 17.4956C12.9804 17.058 12.6256 16.7031 12.188 16.7031H6.98054" fill={color}/>
<path d="M34.3099 18.8779C30.646 18.8779 26.7056 19.7434 26.7056 21.6432V38.2345C26.7056 40.1343 30.646 40.9998 34.3099 40.9998C37.9739 40.9998 41.9143 40.1343 41.9143 38.2345V21.6432C41.9143 19.7434 37.9739 18.8779 34.3099 18.8779ZM40.5317 38.2242C40.4273 38.6078 38.3541 39.6172 34.3099 39.6172C30.2658 39.6172 28.1926 38.6078 28.0882 38.2345V37.1478C29.5593 37.8889 31.9892 38.2345 34.3099 38.2345C36.6307 38.2345 39.0613 37.8854 40.5317 37.1471V38.2242ZM40.5317 35.4589C40.4273 35.8426 38.3541 36.8519 34.3099 36.8519C30.2658 36.8519 28.1926 35.8426 28.0882 35.4693V34.3826C29.5593 35.1237 31.9892 35.4693 34.3099 35.4693C36.6307 35.4693 39.0613 35.1202 40.5317 34.3819V35.4589ZM40.5317 32.6937C40.4273 33.0774 38.3541 34.0867 34.3099 34.0867C30.2658 34.0867 28.1926 33.0774 28.0882 32.7041V31.6173C29.5593 32.3584 31.9892 32.7041 34.3099 32.7041C36.6307 32.7041 39.0613 32.355 40.5317 31.6167V32.6937ZM40.5317 29.9285C40.4273 30.3122 38.3541 31.3215 34.3099 31.3215C30.2658 31.3215 28.1926 30.3122 28.0882 29.9389V28.8521C29.5593 29.5932 31.9892 29.9389 34.3099 29.9389C36.6307 29.9389 39.0613 29.5897 40.5317 28.8514V29.9285ZM40.5317 27.1632C40.4273 27.5469 38.3541 28.5562 34.3099 28.5562C30.2658 28.5562 28.1926 27.5469 28.0882 27.1736V26.0869C29.5593 26.8245 31.9892 27.1736 34.3099 27.1736C36.6307 27.1736 39.0613 26.8245 40.5317 26.0862V27.1632ZM40.5317 24.398C40.4273 24.7817 38.3541 25.791 34.3099 25.791C30.2658 25.791 28.1926 24.7817 28.0882 24.4084V23.3217C29.5593 24.0593 31.9892 24.4084 34.3099 24.4084C36.6307 24.4084 39.0613 24.0593 40.5317 23.321V24.398ZM34.3099 23.0258C30.272 23.0258 28.1995 22.0192 28.0882 21.6556V21.6515C28.1995 21.2664 30.272 20.2605 34.3099 20.2605C38.3195 20.2605 40.3935 21.2519 40.5317 21.6432C40.3935 22.0344 38.3195 23.0258 34.3099 23.0258Z" fill={color}/>
</svg>

    )
}

CollectionLevis.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

CollectionLevis.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default CollectionLevis
    