
import React from "react";
import PropTypes from "prop-types";

const Loader = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="121" height="126" viewBox="0 0 121 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M119.621 55.1695C119.215 52.6267 116.825 50.8944 114.282 51.3001C111.739 51.7058 110.007 54.0962 110.413 56.639C110.417 56.6668 110.422 56.6944 110.427 56.722C115.175 84.6717 96.3658 111.178 68.4161 115.926C40.4664 120.674 13.9599 101.865 9.21192 73.9157C4.46398 45.9659 23.273 19.4594 51.2224 14.7115C68.4109 11.7917 85.9064 17.7957 97.6798 30.6548L77.0257 37.5363C74.5824 38.3493 73.261 40.9888 74.0737 43.4322C74.074 43.4327 74.0743 43.4335 74.0745 43.4341C74.8875 45.8774 77.527 47.1988 79.9704 46.3861C79.9712 46.3858 79.9718 46.3855 79.9723 46.3855L107.946 37.061C109.851 36.4266 111.136 34.6441 111.135 32.6365V4.66243C111.135 2.0874 109.048 0 106.473 0C103.898 0 101.811 2.0874 101.811 4.66243V21.6938C77.7999 -1.14327 39.8222 -0.191774 16.9851 23.8189c5.85199 47.8296 -4.90076 85.8074 19.1102 108.645C43.1212 131.482 81.0986 130.531 103.936 106.52C116.958 92.8276 122.77 73.8012 119.621 55.1695Z" fill={color}/>
</svg>

    )
}

Loader.defaultProps = {
    color: '#219653',
    fontSize: 15
}

Loader.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Loader
    