import React from 'react';
import withStyles from "./jss";
import { classNames, isEmpty } from "../helper/util"

const styles = theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginRight: "15px"
    },
    innerWrapper: {
        width: "44px",
        height: "24px",
        position: "relative",
        backgroundColor: "white",
        padding: "2px",
        borderRadius: "15px",
        cursor: "pointer",
        marginRight: "10px",
    },
    ball: {
        height: "20px",
        position: "absolute",
        borderRadius: "20px",
        width: "20px",
        backgroundColor: "red",
        transition: "all 0.3s",
        left: "2px",
        "&.active": {
            backgroundColor: "green",
            transition: "all 0.3s",
            left: "22px",
        }
    }
})

const Slider = props => {
    const { classes, active, onClick, children} = props
    return (
        <div className={classNames({
            [classes.wrapper]: true,
            [props.classNameWrapper]: !isEmpty(props.classNameWrapper, true)
        })}>
            <div 
                className={classNames({
                    [classes.innerWrapper]: true,
                    [props.classNameInnerWrapper]: !isEmpty(props.classNameInnerWrapper, true),
                })} 
                onClick={onClick}
            >
                <div className={classNames({
                    [classes.ball]: true,
                    "active": active
                })}></div>
            </div>
            {children}
        </div>
    );
}

export default withStyles(styles)(Slider);

