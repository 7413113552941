
import React from "react";
import PropTypes from "prop-types";

const SellOnPayarena = (props) => {
    const {color, fontSize} = props;
    
    const identifier = `path1-outside1-sell-on-payarena-${Math.random().toString(32).substring(8)}`

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id={identifier} maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="42" fill="black">
<rect fill="white" width="39" height="42"/>
<path d="M3.55771 13.8452C3.87936 13.8809 4.16867 13.648 4.20376 13.326L4.53793 10.2618C4.6008 9.68591 5.08511 9.25195 5.66434 9.25195H9.73538V11.2161C9.73538 11.5396 9.99753 11.802 10.3213 11.802C10.6448 11.802 10.9073 11.5396 10.9073 11.2161V9.25195H20.9817V11.2161C20.9817 11.5396 21.2442 11.802 21.568 11.802C21.8914 11.802 22.1539 11.5396 22.1539 11.2161V9.25195H26.2246C26.8039 9.25195 27.2882 9.68622 27.351 10.2621L28.1546 17.6269C28.1872 17.9272 28.4411 18.1494 28.7362 18.1494C28.7573 18.1494 28.779 18.1481 28.8003 18.146C29.1223 18.1109 29.3545 17.8216 29.3194 17.4999L28.5159 10.1348C28.388 8.96326 27.4029 8.07977 26.2246 8.07977H22.1536V7.20911C22.1539 3.78534 19.3683 1 15.9445 1C12.5207 1 9.73538 3.78534 9.73538 7.20911V8.07977H5.66434C4.48605 8.07977 3.50094 8.96326 3.37308 10.1348L3.03891 13.1991C3.00381 13.5208 3.23605 13.8101 3.55771 13.8452ZM10.9073 7.20911C10.9073 4.4317 13.1671 2.17188 15.9445 2.17188C18.7222 2.17188 20.9817 4.4317 20.9817 7.20911V8.07977H10.9073V7.20911Z"/>
<path d="M31.6698 22.5894C30.9523 23.3068 30.9523 24.4744 31.6698 25.1919C32.0284 25.5508 32.4996 25.7302 32.9711 25.7302C33.4423 25.7302 33.9135 25.5508 34.2724 25.1919C34.9898 24.4744 34.9898 23.3068 34.2724 22.5894C33.5549 21.8719 32.3873 21.8719 31.6698 22.5894ZM33.4438 24.3634C33.1832 24.624 32.759 24.624 32.4984 24.3634C32.2377 24.1027 32.2377 23.6785 32.4984 23.4182C32.759 23.1576 33.1832 23.1576 33.4438 23.4182C33.7044 23.6788 33.7044 24.1027 33.4438 24.3634Z"/>
<path d="M37.5488 28.6377L37.2759 21.9036C37.2253 20.6546 36.2072 19.6368 34.9584 19.5861L28.2241 19.313C27.5515 19.2855 26.8929 19.5431 26.4169 20.0192L16.6164 29.8196C16.1251 30.3109 15.874 30.9527 15.8624 31.5979H2.21032L3.95013 15.6469C3.98522 15.325 3.75298 15.036 3.43133 15.0009C3.10906 14.9655 2.82036 15.198 2.78527 15.5197L1.02807 31.6281C0.880973 32.9791 1.31676 34.3341 2.22436 35.346C3.13164 36.3577 4.43169 36.9378 5.79034 36.9378H20.0854L23.3929 40.2453C23.8961 40.7486 24.5568 41 25.2175 41C25.8782 41 26.5392 40.7486 27.0422 40.2453L36.8426 30.4449C37.3187 29.9688 37.5762 29.3103 37.5488 28.6377ZM5.79034 35.766C4.76403 35.766 3.78228 35.3277 3.09685 34.5636C2.64031 34.0548 2.34185 33.4311 2.22619 32.7697H16.119C16.2411 33.0227 16.4071 33.2595 16.6164 33.4689L18.9135 35.766H5.79034ZM36.014 29.6164L26.2136 39.4168C25.6643 39.9658 24.7707 39.9661 24.2214 39.4168L17.445 32.6403C16.896 32.091 16.896 31.1975 17.445 30.6482L27.2457 20.8477C27.4792 20.6143 27.7978 20.4831 28.1268 20.4831C28.1432 20.4831 28.16 20.4834 28.1768 20.484L34.9108 20.7571C35.5544 20.783 36.0787 21.3076 36.105 21.9509L36.3778 28.685C36.3918 29.0316 36.2594 29.371 36.014 29.6164Z"/>
<path d="M24.8879 29.3416C26.0104 29.3416 26.9238 28.4282 26.9238 27.3061C26.9238 26.1837 26.0104 25.2703 24.8879 25.2703C23.7655 25.2703 22.8524 26.1837 22.8524 27.3061C22.8524 28.4282 23.7655 29.3416 24.8879 29.3416ZM24.8879 26.4424C25.3643 26.4424 25.7519 26.8297 25.7519 27.3061C25.7519 27.7822 25.3643 28.1697 24.8879 28.1697C24.4119 28.1697 24.0243 27.7822 24.0243 27.3061C24.0243 26.8297 24.4119 26.4424 24.8879 26.4424Z"/>
<path d="M28.5236 30.9707C27.4011 30.9707 26.488 31.8838 26.488 33.0062C26.488 34.1287 27.4011 35.0421 28.5236 35.0421C29.646 35.0421 30.5591 34.1287 30.5591 33.0062C30.5591 31.8838 29.646 30.9707 28.5236 30.9707ZM28.5236 33.8699C28.0472 33.8699 27.6599 33.4826 27.6599 33.0062C27.6599 32.5302 28.0472 32.1426 28.5236 32.1426C28.9999 32.1426 29.3872 32.5302 29.3872 33.0062C29.3872 33.4826 28.9996 33.8699 28.5236 33.8699Z"/>
<path d="M30.1079 26.7542C29.8791 26.5253 29.508 26.5253 29.2794 26.7542L23.3037 32.7298C23.0749 32.9587 23.0749 33.3298 23.3037 33.5584C23.4179 33.6728 23.568 33.7302 23.7179 33.7302C23.8677 33.7302 24.0179 33.6728 24.1323 33.5584L30.1079 27.5827C30.3368 27.3539 30.3368 26.9828 30.1079 26.7542Z"/>
</mask>
<path d="M3.55771 13.8452C3.87936 13.8809 4.16867 13.648 4.20376 13.326L4.53793 10.2618C4.6008 9.68591 5.08511 9.25195 5.66434 9.25195H9.73538V11.2161C9.73538 11.5396 9.99753 11.802 10.3213 11.802C10.6448 11.802 10.9073 11.5396 10.9073 11.2161V9.25195H20.9817V11.2161C20.9817 11.5396 21.2442 11.802 21.568 11.802C21.8914 11.802 22.1539 11.5396 22.1539 11.2161V9.25195H26.2246C26.8039 9.25195 27.2882 9.68622 27.351 10.2621L28.1546 17.6269C28.1872 17.9272 28.4411 18.1494 28.7362 18.1494C28.7573 18.1494 28.779 18.1481 28.8003 18.146C29.1223 18.1109 29.3545 17.8216 29.3194 17.4999L28.5159 10.1348C28.388 8.96326 27.4029 8.07977 26.2246 8.07977H22.1536V7.20911C22.1539 3.78534 19.3683 1 15.9445 1C12.5207 1 9.73538 3.78534 9.73538 7.20911V8.07977H5.66434C4.48605 8.07977 3.50094 8.96326 3.37308 10.1348L3.03891 13.1991C3.00381 13.5208 3.23605 13.8101 3.55771 13.8452ZM10.9073 7.20911C10.9073 4.4317 13.1671 2.17188 15.9445 2.17188C18.7222 2.17188 20.9817 4.4317 20.9817 7.20911V8.07977H10.9073V7.20911Z" fill={color}/>
<path d="M31.6698 22.5894C30.9523 23.3068 30.9523 24.4744 31.6698 25.1919C32.0284 25.5508 32.4996 25.7302 32.9711 25.7302C33.4423 25.7302 33.9135 25.5508 34.2724 25.1919C34.9898 24.4744 34.9898 23.3068 34.2724 22.5894C33.5549 21.8719 32.3873 21.8719 31.6698 22.5894ZM33.4438 24.3634C33.1832 24.624 32.759 24.624 32.4984 24.3634C32.2377 24.1027 32.2377 23.6785 32.4984 23.4182C32.759 23.1576 33.1832 23.1576 33.4438 23.4182C33.7044 23.6788 33.7044 24.1027 33.4438 24.3634Z" fill={color}/>
<path d="M37.5488 28.6377L37.2759 21.9036C37.2253 20.6546 36.2072 19.6368 34.9584 19.5861L28.2241 19.313C27.5515 19.2855 26.8929 19.5431 26.4169 20.0192L16.6164 29.8196C16.1251 30.3109 15.874 30.9527 15.8624 31.5979H2.21032L3.95013 15.6469C3.98522 15.325 3.75298 15.036 3.43133 15.0009C3.10906 14.9655 2.82036 15.198 2.78527 15.5197L1.02807 31.6281C0.880973 32.9791 1.31676 34.3341 2.22436 35.346C3.13164 36.3577 4.43169 36.9378 5.79034 36.9378H20.0854L23.3929 40.2453C23.8961 40.7486 24.5568 41 25.2175 41C25.8782 41 26.5392 40.7486 27.0422 40.2453L36.8426 30.4449C37.3187 29.9688 37.5762 29.3103 37.5488 28.6377ZM5.79034 35.766C4.76403 35.766 3.78228 35.3277 3.09685 34.5636C2.64031 34.0548 2.34185 33.4311 2.22619 32.7697H16.119C16.2411 33.0227 16.4071 33.2595 16.6164 33.4689L18.9135 35.766H5.79034ZM36.014 29.6164L26.2136 39.4168C25.6643 39.9658 24.7707 39.9661 24.2214 39.4168L17.445 32.6403C16.896 32.091 16.896 31.1975 17.445 30.6482L27.2457 20.8477C27.4792 20.6143 27.7978 20.4831 28.1268 20.4831C28.1432 20.4831 28.16 20.4834 28.1768 20.484L34.9108 20.7571C35.5544 20.783 36.0787 21.3076 36.105 21.9509L36.3778 28.685C36.3918 29.0316 36.2594 29.371 36.014 29.6164Z" fill={color}/>
<path d="M24.8879 29.3416C26.0104 29.3416 26.9238 28.4282 26.9238 27.3061C26.9238 26.1837 26.0104 25.2703 24.8879 25.2703C23.7655 25.2703 22.8524 26.1837 22.8524 27.3061C22.8524 28.4282 23.7655 29.3416 24.8879 29.3416ZM24.8879 26.4424C25.3643 26.4424 25.7519 26.8297 25.7519 27.3061C25.7519 27.7822 25.3643 28.1697 24.8879 28.1697C24.4119 28.1697 24.0243 27.7822 24.0243 27.3061C24.0243 26.8297 24.4119 26.4424 24.8879 26.4424Z" fill={color}/>
<path d="M28.5236 30.9707C27.4011 30.9707 26.488 31.8838 26.488 33.0062C26.488 34.1287 27.4011 35.0421 28.5236 35.0421C29.646 35.0421 30.5591 34.1287 30.5591 33.0062C30.5591 31.8838 29.646 30.9707 28.5236 30.9707ZM28.5236 33.8699C28.0472 33.8699 27.6599 33.4826 27.6599 33.0062C27.6599 32.5302 28.0472 32.1426 28.5236 32.1426C28.9999 32.1426 29.3872 32.5302 29.3872 33.0062C29.3872 33.4826 28.9996 33.8699 28.5236 33.8699Z" fill={color}/>
<path d="M30.1079 26.7542C29.8791 26.5253 29.508 26.5253 29.2794 26.7542L23.3037 32.7298C23.0749 32.9587 23.0749 33.3298 23.3037 33.5584C23.4179 33.6728 23.568 33.7302 23.7179 33.7302C23.8677 33.7302 24.0179 33.6728 24.1323 33.5584L30.1079 27.5827C30.3368 27.3539 30.3368 26.9828 30.1079 26.7542Z" fill={color}/>
<path d="M3.55771 13.8452C3.87936 13.8809 4.16867 13.648 4.20376 13.326L4.53793 10.2618C4.6008 9.68591 5.08511 9.25195 5.66434 9.25195H9.73538V11.2161C9.73538 11.5396 9.99753 11.802 10.3213 11.802C10.6448 11.802 10.9073 11.5396 10.9073 11.2161V9.25195H20.9817V11.2161C20.9817 11.5396 21.2442 11.802 21.568 11.802C21.8914 11.802 22.1539 11.5396 22.1539 11.2161V9.25195H26.2246C26.8039 9.25195 27.2882 9.68622 27.351 10.2621L28.1546 17.6269C28.1872 17.9272 28.4411 18.1494 28.7362 18.1494C28.7573 18.1494 28.779 18.1481 28.8003 18.146C29.1223 18.1109 29.3545 17.8216 29.3194 17.4999L28.5159 10.1348C28.388 8.96326 27.4029 8.07977 26.2246 8.07977H22.1536V7.20911C22.1539 3.78534 19.3683 1 15.9445 1C12.5207 1 9.73538 3.78534 9.73538 7.20911V8.07977H5.66434C4.48605 8.07977 3.50094 8.96326 3.37308 10.1348L3.03891 13.1991C3.00381 13.5208 3.23605 13.8101 3.55771 13.8452ZM10.9073 7.20911C10.9073 4.4317 13.1671 2.17188 15.9445 2.17188C18.7222 2.17188 20.9817 4.4317 20.9817 7.20911V8.07977H10.9073V7.20911Z" stroke={color} mask={`url(#${identifier})`}/>
<path d="M31.6698 22.5894C30.9523 23.3068 30.9523 24.4744 31.6698 25.1919C32.0284 25.5508 32.4996 25.7302 32.9711 25.7302C33.4423 25.7302 33.9135 25.5508 34.2724 25.1919C34.9898 24.4744 34.9898 23.3068 34.2724 22.5894C33.5549 21.8719 32.3873 21.8719 31.6698 22.5894ZM33.4438 24.3634C33.1832 24.624 32.759 24.624 32.4984 24.3634C32.2377 24.1027 32.2377 23.6785 32.4984 23.4182C32.759 23.1576 33.1832 23.1576 33.4438 23.4182C33.7044 23.6788 33.7044 24.1027 33.4438 24.3634Z" stroke={color} mask={`url(#${identifier})`}/>
<path d="M37.5488 28.6377L37.2759 21.9036C37.2253 20.6546 36.2072 19.6368 34.9584 19.5861L28.2241 19.313C27.5515 19.2855 26.8929 19.5431 26.4169 20.0192L16.6164 29.8196C16.1251 30.3109 15.874 30.9527 15.8624 31.5979H2.21032L3.95013 15.6469C3.98522 15.325 3.75298 15.036 3.43133 15.0009C3.10906 14.9655 2.82036 15.198 2.78527 15.5197L1.02807 31.6281C0.880973 32.9791 1.31676 34.3341 2.22436 35.346C3.13164 36.3577 4.43169 36.9378 5.79034 36.9378H20.0854L23.3929 40.2453C23.8961 40.7486 24.5568 41 25.2175 41C25.8782 41 26.5392 40.7486 27.0422 40.2453L36.8426 30.4449C37.3187 29.9688 37.5762 29.3103 37.5488 28.6377ZM5.79034 35.766C4.76403 35.766 3.78228 35.3277 3.09685 34.5636C2.64031 34.0548 2.34185 33.4311 2.22619 32.7697H16.119C16.2411 33.0227 16.4071 33.2595 16.6164 33.4689L18.9135 35.766H5.79034ZM36.014 29.6164L26.2136 39.4168C25.6643 39.9658 24.7707 39.9661 24.2214 39.4168L17.445 32.6403C16.896 32.091 16.896 31.1975 17.445 30.6482L27.2457 20.8477C27.4792 20.6143 27.7978 20.4831 28.1268 20.4831C28.1432 20.4831 28.16 20.4834 28.1768 20.484L34.9108 20.7571C35.5544 20.783 36.0787 21.3076 36.105 21.9509L36.3778 28.685C36.3918 29.0316 36.2594 29.371 36.014 29.6164Z" stroke={color} mask={`url(#${identifier})`}/>
<path d="M24.8879 29.3416C26.0104 29.3416 26.9238 28.4282 26.9238 27.3061C26.9238 26.1837 26.0104 25.2703 24.8879 25.2703C23.7655 25.2703 22.8524 26.1837 22.8524 27.3061C22.8524 28.4282 23.7655 29.3416 24.8879 29.3416ZM24.8879 26.4424C25.3643 26.4424 25.7519 26.8297 25.7519 27.3061C25.7519 27.7822 25.3643 28.1697 24.8879 28.1697C24.4119 28.1697 24.0243 27.7822 24.0243 27.3061C24.0243 26.8297 24.4119 26.4424 24.8879 26.4424Z" stroke={color} mask={`url(#${identifier})`}/>
<path d="M28.5236 30.9707C27.4011 30.9707 26.488 31.8838 26.488 33.0062C26.488 34.1287 27.4011 35.0421 28.5236 35.0421C29.646 35.0421 30.5591 34.1287 30.5591 33.0062C30.5591 31.8838 29.646 30.9707 28.5236 30.9707ZM28.5236 33.8699C28.0472 33.8699 27.6599 33.4826 27.6599 33.0062C27.6599 32.5302 28.0472 32.1426 28.5236 32.1426C28.9999 32.1426 29.3872 32.5302 29.3872 33.0062C29.3872 33.4826 28.9996 33.8699 28.5236 33.8699Z" stroke={color} mask={`url(#${identifier})`}/>
<path d="M30.1079 26.7542C29.8791 26.5253 29.508 26.5253 29.2794 26.7542L23.3037 32.7298C23.0749 32.9587 23.0749 33.3298 23.3037 33.5584C23.4179 33.6728 23.568 33.7302 23.7179 33.7302C23.8677 33.7302 24.0179 33.6728 24.1323 33.5584L30.1079 27.5827C30.3368 27.3539 30.3368 26.9828 30.1079 26.7542Z" stroke={color} mask={`url(#${identifier})`}/>
</svg>

    )
}

SellOnPayarena.defaultProps = {
    color: '#E7792B',
    fontSize: 15
}

SellOnPayarena.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SellOnPayarena
    