
import React from "react";
import PropTypes from "prop-types";

const ListBusiness = (props) => {
    const {color, fontSize} = props;

    return (
        <svg style={{ width: '1em', height: '1em', fontSize }} width="29" height="38" viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.366 3.57757H21.3849H18.7872C18.2862 1.52668 16.4331 0 14.2303 0C12.0273 0 10.1741 1.52668 9.67308 3.57757H7.0755H4.09443C1.83677 3.57757 0 5.41426 0 7.67177V33.9056C0 36.1632 1.83677 38 4.09443 38H24.3659C26.6236 38 28.4603 36.1632 28.4603 33.9056V7.67177C28.4604 5.41426 26.6237 3.57757 24.366 3.57757ZM10.6529 5.80413H10.6531C11.268 5.80413 11.7664 5.30568 11.7664 4.69085C11.7664 4.68988 11.7663 4.68899 11.7663 4.68803C11.7678 3.33049 12.8725 2.22656 14.2303 2.22656C15.5888 2.22656 16.6941 3.33205 16.6941 4.69085C16.6941 5.30568 17.1926 5.80413 17.8074 5.80413H17.8083H20.2717V7.67177C20.2717 8.70163 19.4338 9.53956 18.4039 9.53956H10.0566C9.02671 9.53956 8.18878 8.70163 8.18878 7.67177V5.80413H10.6529ZM26.2338 33.9056C26.2338 34.9355 25.3959 35.7734 24.366 35.7734H4.09443C3.06449 35.7734 2.22656 34.9355 2.22656 33.9056V7.67177C2.22656 6.64191 3.06449 5.80413 4.09443 5.80413H5.96222V7.67177C5.96222 9.92936 7.79898 11.7661 10.0566 11.7661H18.4038C20.6615 11.7661 22.4982 9.92936 22.4982 7.67177V5.80413H24.3659C25.3959 5.80413 26.2337 6.64198 26.2337 7.67177V33.9056H26.2338Z" fill={color}/>
<path d="M8.67368 28.9447L7.07582 30.5425L6.67037 30.1372C6.23552 29.7026 5.53073 29.7026 5.09596 30.1374C4.66126 30.5722 4.66141 31.2771 5.09618 31.7118L6.28881 32.904C6.50619 33.1214 6.79104 33.23 7.0759 33.23C7.36075 33.23 7.64575 33.1213 7.86306 32.904L10.248 30.5191C10.6828 30.0843 10.6828 29.3795 10.2481 28.9447C9.81338 28.51 9.10845 28.5099 8.67368 28.9447Z" fill={color}/>
<path d="M8.67367 21.7899L7.07581 23.3877L6.67035 22.9824C6.23558 22.5477 5.53065 22.5478 5.09595 22.9827C4.66125 23.4176 4.6614 24.1225 5.09625 24.5571L6.28887 25.7494C6.50626 25.9667 6.79103 26.0753 7.07596 26.0753C7.36081 26.0753 7.64581 25.9666 7.86312 25.7493L10.2481 23.3643C10.6828 22.9296 10.6828 22.2247 10.2481 21.7899C9.8133 21.355 9.10837 21.355 8.67367 21.7899Z" fill={color}/>
<path d="M6.28883 18.5954C6.50622 18.8127 6.79107 18.9214 7.076 18.9214C7.36092 18.9214 7.64585 18.8128 7.86316 18.5954L10.2481 16.2104C10.6829 15.7756 10.6829 15.0708 10.2481 14.636C9.81341 14.2012 9.10848 14.2012 8.6737 14.636L7.07592 16.2338L6.67047 15.8284C6.23569 15.3937 5.53076 15.3937 5.09606 15.8285C4.66129 16.2633 4.66136 16.9682 5.09614 17.4029L6.28883 18.5954Z" fill={color}/>
<path d="M22.5773 31.0034H14.2305C13.6156 31.0034 13.1172 31.5019 13.1172 32.1167C13.1172 32.7315 13.6156 33.23 14.2305 33.23H22.5773C23.1922 33.23 23.6906 32.7315 23.6906 32.1167C23.6906 31.5019 23.1922 31.0034 22.5773 31.0034Z" fill={color}/>
<path d="M22.5773 23.8488H14.2305C13.6156 23.8488 13.1172 24.3472 13.1172 24.962C13.1172 25.5769 13.6156 26.0753 14.2305 26.0753H22.5773C23.1922 26.0753 23.6906 25.5769 23.6906 24.962C23.6906 24.3472 23.1922 23.8488 22.5773 23.8488Z" fill={color}/>
<path d="M22.5773 16.6947H14.2305C13.6156 16.6947 13.1172 17.1932 13.1172 17.808C13.1172 18.4228 13.6156 18.9213 14.2305 18.9213H22.5773C23.1922 18.9213 23.6906 18.4228 23.6906 17.808C23.6906 17.1932 23.1922 16.6947 22.5773 16.6947Z" fill={color}/>
</svg>

    )
}

ListBusiness.defaultProps = {
    color: '#03A4E4',
    fontSize: 15
}

ListBusiness.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ListBusiness
    